import * as yup from 'yup'
import { useFormik } from 'formik'
import { Modal, Button, Avatar, Input, Select, Form } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'

import Error from 'components/Error'

import { useAzuro } from 'api/Azuro'
import { useStorage } from 'hooks/useContext'

import styles from './styles.module.scss'
import BugTable from '../BugTable'
const { Option } = Select

const Label = ({ value }) => {
  return (
    <div className={styles.label} style={{ whiteSpace: 'pre-wrap' }}>
      {value}
    </div>
  )
}

export const CreateBugModal = ({ isOpen, justClose, data, onClose }) => {
  const { employees, getListEmployees, userStories, getListUserStories } =
    useStorage()

  const [loading, setLoading] = useState(false)
  const { currentSprint, createBug, getSprint, getTasks, tasks } = useAzuro()

  useEffect(() => {
    getListEmployees()
    getListUserStories()
    getSprint()
  }, [getListEmployees])

  useEffect(() => {
    if (data.id) {
      getTasks(data.id)
    }
  }, [data])

  const employeesOption = useMemo(() => {
    return employees.map((user) => ({
      id: user.id,
      value: user.id,
      label: user.name,
      url: user.img,
      email: user.email
    }))
  }, [employees])

  const userStoriesOption = useMemo(() => {
    return userStories.map((story) => ({
      id: `${story.id}`,
      value: story.id,
      label: story.title
    }))
  }, [userStories])

  const handleSave = async (value) => {
    try {
      setLoading(true)
      await createBug(value)

      resetForm()
      if (data.id) {
        await getTasks(data.id)
      }
      // onClose()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    justClose()
    resetForm()
  }

  const bugName = (problemNumber, problemName) => {
    const sprintNumber = currentSprint?.name?.split(' ')[1]
    return `${sprintNumber}. Системная проблема №${problemNumber}. ${problemName}`
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        title: bugName(data.id, data.name),
        priority: 2,
        systemDecisionId: data.id,
        description: data.description,
        employeeEmail: null,
        userStoryId: null,
        tags: ['Системная проблема', 'servicedesk', 'prod'],
        reproSteps: ''
      },
      validationSchema: yup.object().shape({
        userStoryId: yup.string().nullable().required('Это поле обязательно'),
        employeeEmail: yup.string().nullable().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const changeSelectHandler = (value, type) => {
    setFieldValue(type, value)
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={1200}
      onCancel={handleCloseModal}
      title={'Создать баг в Azure DevOps'}
    >
      <div className={styles.container}>
        <Form
          style={{ width: '45%' }}
          layout='vertical'
          onFinish={handleSubmit}
          loading={loading}
        >
          <Form.Item
            label='Ответственный за реализацию'
            extra={
              touched.employeeEmail && <Error message={errors.employeeEmail} />
            }
            className={styles.selector}
          >
            <Select
              onChange={(data) => changeSelectHandler(data, 'employeeEmail')}
              value={values.employeeEmail}
              optionFilterProp='children'
              mode='single'
              filterOption={(input, option) => {
                return (
                  option.children[1]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                )
              }}
              size={'large'}
              showSearch
            >
              {employeesOption.map((o) => (
                <Option key={o.id} value={o.email} style={{ display: 'flex' }}>
                  <Avatar src={o.url} style={{ marginRight: '5px' }} />
                  {o.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='User story'
            extra={
              touched.userStoryId && <Error message={errors.userStoryId} />
            }
          >
            <Select
              onChange={(data) => changeSelectHandler(data, 'userStoryId')}
              value={values.userStoryId}
              optionFilterProp='children'
              mode='single'
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                )
              }}
              size={'large'}
              showSearch
            >
              {userStoriesOption.map((o) => (
                <Option key={o.id} value={o.id} style={{ display: 'flex' }}>
                  {o.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='Приоритет'
            extra={touched.priority && <Error message={errors.priority} />}
          >
            <Select
              onChange={(data) => changeSelectHandler(data, 'priority')}
              value={values.priority}
              optionFilterProp='children'
              mode='single'
            >
              {[
                { id: 1, label: 'Приоритет 1' },
                { id: 2, label: 'Приоритет 2' },
                { id: 3, label: 'Приоритет 3' },
                { id: 4, label: 'Приоритет 4' }
              ].map((o) => (
                <Option key={o.id} value={o.id} style={{ display: 'flex' }}>
                  {o.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='Теги'
            extra={touched.tags && <Error message={errors.tags} />}
          >
            <Select
              onChange={(data) => changeSelectHandler(data, 'tags')}
              value={values.tags}
              optionFilterProp='children'
              mode='multiple'
            >
              {[
                { id: 1, label: 'Системная проблема' },
                { id: 2, label: 'servicedesk' },
                { id: 3, label: 'prod' }
              ].map((o) => (
                <Option key={o.id} value={o.label} style={{ display: 'flex' }}>
                  {o.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='Имя баги' style={{ fontWeight: 500 }}>
            <Label value={values.title} />
          </Form.Item>

          <Form.Item label='Системная информация' style={{ fontWeight: 500 }}>
            <Label value={values.description} />
          </Form.Item>

          <Form.Item
            extra={touched.reproSteps && <Error message={errors.reproSteps} />}
          >
            <Input.TextArea
              value={values.reproSteps}
              placeholder='Шаги воспроизведения'
              onChange={handleChange('reproSteps')}
              style={{ width: 500, marginTop: 25 }}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>

          <Button loading={loading} type='primary' htmlType='submit'>
            Создать
          </Button>
        </Form>
        <div style={{ width: '50%' }}>
          <BugTable data={tasks} loading={loading} />
        </div>
      </div>
    </Modal>
  )
}
