import Icon, { TagsOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import React from 'react'
import { ReactComponent as listIcon } from 'icons/list.svg'
import { ReactComponent as problemIcon } from 'icons/problem.svg'
import { ReactComponent as merchantIcon } from 'icons/merchant.svg'
import { ReactComponent as softwareIcon } from 'icons/software.svg'
import { ReactComponent as placesIcon } from 'icons/places.svg'
import { ReactComponent as reportsIcon } from 'icons/reports.svg'
import { ReactComponent as usersIcon } from 'icons/users.svg'
import { ReactComponent as telegramMenu } from 'icons/telegramMenu.svg'
import {
  DeploymentUnitOutlined,
  FieldTimeOutlined,
  HddOutlined,
  ScheduleOutlined
} from '@ant-design/icons'
import styles from './styles.module.scss'

export const ListIcon = () => {
  return <Icon component={listIcon} className={styles.icon} />
}

export const ProplemIcon = () => {
  return (
    <Icon
      component={problemIcon}
      className={clsx(styles.icon, styles.problemIcon)}
    />
  )
}

export const MerchantIcon = () => {
  return <Icon component={merchantIcon} className={styles.icon} />
}

export const SoftwareIcon = () => {
  return <Icon component={softwareIcon} className={styles.icon} />
}

export const PlacesIcon = () => {
  return (
    <Icon
      component={placesIcon}
      className={clsx(styles.icon, styles.placesIcon)}
    />
  )
}

export const ReportsIcon = () => {
  return <Icon component={reportsIcon} className={styles.icon} />
}

export const UsersIcon = () => {
  return <Icon component={usersIcon} className={styles.icon} />
}
export const TelegramIcon = () => {
  return <Icon component={telegramMenu} className={styles.icon} />
}

export const TagsIcon = () => {
  return <Icon component={TagsOutlined} className={styles.icon} />
}

export const TypeIcon = () => {
  return <Icon component={DeploymentUnitOutlined} className={styles.icon} />
}

export const DateBaseIcon = () => {
  return <Icon component={HddOutlined} className={styles.icon} />
}

export const ScheduleIcon = () => {
  return <Icon component={ScheduleOutlined} className={styles.icon} />
}

export const FieldTimeOutlinedIcon = () => {
  return <Icon component={FieldTimeOutlined} className={styles.icon} />
}
