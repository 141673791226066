import { useHistory } from 'react-router'
import { useDebouncedCallback } from 'use-debounce/lib'

import { Table, Typography, Form, Input, Row } from 'antd'
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import Icon from '@ant-design/icons'
import NumericInput from 'components/NumericInput'
import useStateFromQuery from 'hooks/useStateFromQuery'
import io from 'socket.io-client'
import { normalizeKiosk } from 'utils/helpers'
import Pagination from 'components/Pagination'
import MultiSelect from 'components/MultiSelect'
import RangePicker from 'components/RangePicker'
import StatusIndicator from 'components/StatusIndicator'

import axios from 'utils/axios'
import {
  formatTime,
  makeColorIndicator,
  dateToApiDateFormat,
  getDateFromApiDateFormat
} from 'utils/helpers'

import { ReactComponent as radiationIcon } from 'icons/radiation.svg'

import { MailOutlined } from '@ant-design/icons'

// import 'antd/dist/antd.css'
import styles from './styles.module.scss'

const FILTER_INITIAL = {
  search: '',
  id: '',
  clientPhone: '',
  status: [],
  ticketDateStart: null,
  ticketDateEnd: null,
  detectedDateStart: null,
  detectedDateEnd: null,
  users: [],
  newMessage: false,
  type: [],
  page: 1,
  perPage: 10,
  tags: [],
  pans: []
}

const { Title } = Typography
const Incidents = () => {
  const history = useHistory()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useStateFromQuery(FILTER_INITIAL, [
    {
      key: 'ticketDateStart',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'ticketDateEnd',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'closedDateStart',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'closedDateEnd',
      cb: getDateFromApiDateFormat
    },
    {
      key: 'users',
      cb: (i) => +i
    }
  ])

  const [pagination, setPagination] = useState({})
  const [statuses, setStatuses] = useState([])
  const [tags, setTags] = useState([])
  const [pans, setPans] = useState([])
  const [types, setTypes] = useState([])
  const [users, setUsers] = useState([])

  const getStatuses = useCallback(async () => {
    const res = await axios.get('/ticket/statuses')
    setStatuses(res.data)
  }, [])

  const getTags = useCallback(async () => {
    const res = await axios.get('/tag', {
      params: { type: 1 }
    })

    setTags([...res.data])
    setTags(res.data)
  }, [])

  const getPans = useCallback(async () => {
    const res = await axios.get('/pan')
    setPans(res.data)
  }, [])

  const getTypes = useCallback(async () => {
    const res = await axios.get(`/type?group=${2}`)
    setTypes([...res.data])
  }, [])

  const getUsers = useCallback(async () => {
    const res = await axios.get('/user/staff')
    res.data && setUsers(res.data.map(({ id, name }) => ({ id, label: name })))
  }, [])

  useEffect(() => {
    if (!statuses.length) {
      getStatuses()
    }
    if (!tags.length) {
      getTags()
    }
    if (!types.length) {
      getTypes()
    }
    if (!pans.length) {
      getPans()
    }

    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsStatuses = useMemo(() => {
    return statuses.map((item) => ({ id: item.id, label: item.name }))
  }, [statuses])

  const optionsTypes = useMemo(() => {
    return types.map((item) => ({ id: item.id, label: item.name }))
  }, [types])

  const getData = useDebouncedCallback(async () => {
    try {
      setLoading(true)

      const res = await axios.get('/ticket', {
        params: {
          ...filter,
          ticketDateStart: dateToApiDateFormat(filter.ticketDateStart),
          ticketDateEnd: dateToApiDateFormat(filter.ticketDateEnd),
          closedDateStart: dateToApiDateFormat(filter.closedDateStart),
          closedDateEnd: dateToApiDateFormat(filter.closedDateEnd),
          group: 2
        }
      })

      setData(
        res.data.map((i) => {
          let colorIndicator = makeColorIndicator(i.status.name)
          return { ...i, colorIndicator }
        })
      )
      setPagination(res.pagination)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, 500)

  useEffect(() => {
    getData()
  }, [filter, getData])

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_SOCKET)
    socket.on('ticket', () => getData())
    socket.on('message', () => getData())

    return () => socket.disconnect()
  }, [getData])

  const handleChangePagination = (value) => {
    setFilter({ ...filter, ...value })
  }

  const columns = useMemo(
    () => [
      {
        id: 'id',
        title: '№',
        dataIndex: 'id',
        key: 'id',
        render: (value, record) => (
          <div className={styles.idContainer}>
            {record.newMessage && (
              <div className={styles.idMail}>{<MailOutlined />}</div>
            )}
            <div className={styles.idSelf}>{value}</div>
          </div>
        )
      },
      {
        title: 'Стадия',
        dataIndex: 'status',
        key: 'status',
        width: '5%',
        render: (value, record) => (
          <div className={styles.statusContainer}>
            <StatusIndicator
              indicator={record.colorIndicator}
              content={value.name}
            />
            {record.systemDecisionId && (
              <Icon className={styles.problemIcon} component={radiationIcon} />
            )}
          </div>
        )
      },
      {
        title: 'Место проблемы',
        dataIndex: 'kiosk',
        key: 'kiosk',
        render: (value, record) => {
          return record?.merchantKiosk ? (
            <div>{normalizeKiosk(record?.merchantKiosk)}</div>
          ) : (
            <div>{value}</div>
          )
        }
      },
      // {
      //   title: 'Дата и время инцидента',
      //   dataIndex: 'formFilledDate',
      //   key: 'formFilledDate',
      //   render: (value) => (
      //     <div className={styles.date}>{formatTime(value)}</div>
      //   )
      // },
      {
        title: 'Дата и время инцидента',
        dataIndex: 'detectedAt',
        key: 'detectedAt',
        render: (value) => (
          <div className={styles.date}>{formatTime(value)}</div>
        )
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        render: (value) => (value ? value.name : '-')
      },
      {
        title: 'Описание инцидента',
        dataIndex: 'description',
        key: 'description',
        render: (value) => <div className={styles.description}>{value}</div>
      },
      {
        title: 'Сотрудник',
        dataIndex: 'user',
        key: 'user',
        render: (value) => (value ? value.name : '-')
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleClickRow = (record) => {
    return {
      onClick: () => history.push(`/admin/incident/${record.id}`),
      onMouseDown: (e) => {
        if (e.button === 1)
          window.open(`/admin/incident/${record.id}`, '_blank')
      }
    }
  }

  const handleChangeFilter = (name) => (event) => {
    setFilter({ ...filter, [name]: event.target.value, page: 1 })
  }

  const handleChangeFilterSelect = (name) => (value) => {
    setFilter({ ...filter, [name]: value, page: 1 })
  }

  const handleChangeFilterDate = (name) => (moment) => {
    const start = moment ? moment[0] : null
    const end = moment ? moment[1] : null

    if (name === 'ticket') {
      setFilter({
        ...filter,
        ticketDateStart: start,
        ticketDateEnd: end,
        page: 1
      })
    } else if (name === 'closed') {
      setFilter({
        ...filter,
        closedDateStart: start,
        closedDateEnd: end,
        page: 1
      })
    }
  }

  return (
    <div>
      <Title className={styles.mb} level={2}>
        Список инцидентов
      </Title>
      <Form
        wrapperCol={{
          span: 10
        }}
        layout='vertical'
      >
        <Row>
          <Form.Item label='Номер' className={styles.formItem}>
            <NumericInput
              value={filter.id}
              style={{ width: '100px' }}
              placeholder='№ обращения'
              onChange={handleChangeFilter('id')}
            />
          </Form.Item>

          <Form.Item label='Поиск' className={styles.formItem}>
            <Input
              value={filter.search}
              style={{ width: '250px' }}
              placeholder='По месту или описанию проблемы'
              onChange={handleChangeFilter('search')}
            />
          </Form.Item>

          <Form.Item label='Сотрудник' className={styles.formItem}>
            <MultiSelect
              mode='multiple'
              maxTagCount={1}
              maxTagTextLength={12}
              options={users}
              style={{ width: 150 }}
              value={filter.users}
              onChange={handleChangeFilterSelect('users')}
            />
          </Form.Item>

          <Form.Item label='Дата и время инцидента' className={styles.formItem}>
            <RangePicker
              value={[filter.ticketDateStart, filter.ticketDateEnd]}
              onChange={handleChangeFilterDate('ticket')}
            />
          </Form.Item>

          <Form.Item
            label='Дата и время закрытия инцидента'
            className={styles.formItem}
          >
            <RangePicker
              value={[filter.closedDateStart, filter.closedDateEnd]}
              onChange={handleChangeFilterDate('closed')}
            />
          </Form.Item>

          <Form.Item label='Тип' className={styles.formItem}>
            <MultiSelect
              value={filter.type}
              mode='multiple'
              style={{ width: '200px' }}
              options={optionsTypes}
              maxTagCount={1}
              maxTagTextLength={12}
              onChange={handleChangeFilterSelect('type')}
            />
          </Form.Item>
          <Form.Item label='Стадия' className={styles.formItem}>
            <MultiSelect
              value={filter.status}
              mode='multiple'
              style={{ width: '200px' }}
              options={optionsStatuses}
              maxTagCount={1}
              maxTagTextLength={12}
              onChange={handleChangeFilterSelect('status')}
            />
          </Form.Item>
        </Row>
      </Form>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        scroll={{ x: 1200 }}
        onRow={handleClickRow}
        rowClassName={styles.row}
      />

      <Pagination onChange={handleChangePagination} pagination={pagination} />
    </div>
  )
}

export default Incidents
