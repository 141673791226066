import { useParams } from 'react-router-dom'
import React, { useEffect, useCallback, useState } from 'react'
import { Typography, Row, Timeline } from 'antd'
import styles from './styles.module.scss'
const { Title } = Typography

import axios from 'utils/axios'
const Announcement = () => {
  const [announcements, setAnnouncements] = useState([])

  const getAnnouncements = useCallback(async () => {
    const res = await axios.get('/announcement/')

    if (res?.data) {
      const array = res.data.map(({ id, name, time, status }) => ({
        id,
        label: name,
        children: `${time} минут | ${status.name}`
      }))
      setAnnouncements(array)
    }
  }, [])

  useEffect(() => {
    getAnnouncements()
  }, [])
  return (
    <div className={styles.container}>
      <Row>
        <Title level={2}>Расписание уведомлений для сотрудников</Title>
      </Row>
      <div className={styles.time}>
        <Timeline mode={'left'} items={announcements} />
      </div>
    </div>
  )
}

export default Announcement
