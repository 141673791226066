import axios from 'utils/axios'
import { useNotification } from 'hooks/useNotification'

import React from 'react'

export const useMerchantBotAPI = () => {
  const { createNotification } = useNotification()

  const [loading, setLoading] = React.useState(false)

  const fetchAllChats = async () => {
    try {
      const res = await axios.get('/marchant-bot-chat/chats')
      return res.data
    } catch {
      /*empty */
    }
  }

  const fetchSendMessage = async (data) => {
    try {
      const res = await axios.post('/marchant-bot-chat/message', data)
      createNotification(res.message, 'success')
    } catch {
      /*empty */
    }
  }

  const fetchMessages = async (filter) => {
    try {
      setLoading(true)
      const res = await axios.get('/marchant-bot-chat/messages', {
        params: { ...filter }
      })
      return { data: res.data, pagination: res.pagination }
    } catch {
      /*empty */
    } finally {
      setLoading(false)
    }
  }

  return { fetchAllChats, fetchSendMessage, fetchMessages, loading }
}
