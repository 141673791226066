import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Input, Button, Select, Result } from 'antd'

import Error from 'components/Error'
import alert from 'utils/alert'
import MultiSelect from 'components/MultiSelect'
import SingleSelect from 'components/SingleSelect'
import ConditionalRender from 'components/ConditionalRender'
import { useProfile } from 'hooks/useContext'

import { useHistory as useEventHistory, EVENTS } from 'api/History'
import axios from 'utils/axios'
import { normalizeKioskAddress } from 'utils/helpers'

import styles from './styles.module.scss'

const { Option } = Select

const validationSchema = yup.object().shape({
  typeId: yup.number().required('Это поле обязательно'),
  comment: yup.string().when('typeId', {
    is: 1,
    then: yup.string().required('Это поле обязательно')
  }),
  merchantId: yup.string().required('Это поле обязательно'),
  softwares: yup.array().when('typeId', {
    is: 1,
    then: yup
      .array()
      .min(1, 'Минимум один модуль')
      .required('Это поле обязательно')
  }),
  problemPlaceId: yup.string().required('Это поле обязательно'),
  truthKiosk: yup.string().when('problemPlaceId', {
    is: '1',
    then: yup.string().required('Это поле обязательно')
  })
})

export const AnalysisTab = ({ data, update }) => {
  const [loading, setLoading] = useState(false)
  const [isChanging, setIsChanging] = useState(false)
  const [softwares, setSoftwares] = useState([])
  const [merchants, setMerchants] = useState([])
  const [places, setPlaces] = useState([])
  const [kiosks, setKiosks] = useState([])
  const [types, setTypes] = useState([])

  const { createHistory } = useEventHistory()

  const { user, isAdmin } = useProfile()

  const handleSave = async (value) => {
    try {
      setLoading(true)
      await axios.put(`/ticket/${data.id}/analys`, value)
      await createHistory({
        ticketId: data.id,
        eventId: EVENTS.ADD_CASE_ANALYSIS
      })
      setIsChanging(false)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      update()
    }
  }

  const handleEditForm = async (value) => {
    try {
      if (isChanging) {
        setLoading(true)
        await axios.put(`/ticket/${data.id}/analys`, value)
        await createHistory({
          ticketId: data.id,
          eventId: EVENTS.EDIT_CASE_ANALYSIS
        })
        await update()
        setIsChanging(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleChanging = () => {
    setIsChanging(!isChanging)
  }

  const getAddressesKiosks = useCallback(async () => {
    try {
      const res = await axios.get('/merchant/kiosks')
      const kiosks = res.data
      setKiosks(kiosks)
    } catch (error) {
      alert.error(error.response)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSoftwares = useCallback(async () => {
    const res = await axios.get('/software')
    setSoftwares(res.data)
  }, [])
  const getMerchants = useCallback(async () => {
    const res = await axios.get('/merchant')
    setMerchants(res.data)
  }, [])
  const getPlaces = useCallback(async () => {
    const res = await axios.get('/problemplace')
    setPlaces(res.data)
  }, [])
  const getTypes = useCallback(async () => {
    const res = await axios.get(`/type?group=${2}`)
    setTypes(res.data)
  }, [])

  const optionsSoftwares = useMemo(() => {
    return softwares.map((item) => ({ id: item.id, label: item.name }))
  }, [softwares])

  const optionsMerchants = useMemo(() => {
    const merchant = merchants.map((item) => ({
      id: item.id,
      label: item.name
    }))
    merchant.unshift({ id: 0, label: 'Все' })
    return merchant
  }, [merchants])
  const optionsPlaces = useMemo(() => {
    return places.map((item) => ({ id: item.id, label: item.name }))
  }, [places])
  const optionsKiosks = useMemo(() => {
    return kiosks.map((item) => ({
      id: item.id,
      label: normalizeKioskAddress(item)
    }))
  }, [kiosks])

  const activeKiosk = useMemo(() => {
    const activeKiosk = optionsKiosks.find(
      (item) => item.id === data?.merchantKiosk?.id
    )
    return activeKiosk?.label || ''
  }, [data, optionsKiosks])

  const optionsTypes = useMemo(() => {
    return types.map((item) => ({ id: item.id, label: item.name }))
  }, [types])

  const activeType = useMemo(() => {
    const active = optionsTypes.find((item) => item.id === data?.type?.id)

    return active?.label || ''
  }, [data, optionsTypes])

  useEffect(() => {
    getSoftwares()
    getMerchants()
    getPlaces()
    getAddressesKiosks()
    getTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = useMemo(() => {
    // const activeKiosk = optionsKiosks.find(
    //   (item) => item.id === data?.merchantKiosk?.id
    // )
    return {
      typeId: data.type ? data.type?.id : '',
      comment: data.comment ? data.comment : '',
      softwares:
        data.softwares?.length > 0
          ? data.softwares?.map((item) => item.id)
          : [],
      merchantId:
        data.merchants?.length > 0
          ? data.merchants.length > 1
            ? 0
            : data.merchants[0]?.id
          : '',
      problemPlaceId: data.problemPlace?.id ? data.problemPlace?.id : 1,
      truthKiosk: data?.kioskId || ''
    }
  }, [data])

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => {
        data.comment === '' || data.comment === null
          ? handleSave(values)
          : handleEditForm(values)
      },
      initialValues,
      validationSchema,
      enableReinitialize: true
    })

  const handleChange = useCallback(
    (name) => (event) => {
      setFieldValue(name, event.target.value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getMerchantById = useCallback(
    (merchantId) => {
      const found = optionsMerchants.find(
        (merchant) => merchant.id === merchantId
      )

      return found?.label || 'Нет информации'
    },
    [optionsMerchants]
  )

  const getOptionPlaceById = useCallback(
    (optionPlaceId) => {
      const found = optionsPlaces.find(
        (optionPlace) => optionPlace.id === optionPlaceId
      )
      return found?.label || 'Нет информации'
    },

    [optionsPlaces]
  )

  const getSoftwaresByListId = useCallback(
    (softwaresIds) => {
      let list = []

      softwaresIds.map((softwareId) => {
        optionsSoftwares.some((item) => {
          if (item.id === softwareId) {
            list.push(item.label)
          }
        })
      })
      return list.join(', ')
    },

    [optionsSoftwares]
  )

  const handleChangeSelect = (name) => (value, other) => {
    setFieldValue(name, value)
  }

  const handleCancelEdit = () => {
    setIsChanging(false)
    data.type?.id !== values.typeId && resetForm()
  }

  if (data.status?.id === 1) {
    return (
      <Result
        status='warning'
        title='Для анализа нужно взять обращение в работу'
      />
    )
  }

  const statusClosedAt = data.closedAt

  const currentUser = data?.user?.id === user.id

  const Label = ({ value }) => {
    return (
      <div className={styles.label} style={{ whiteSpace: 'pre-wrap' }}>
        {value}
      </div>
    )
  }

  const conditionTypeTicket =
    data.type?.id !== 1 ||
    data.type?.id !== 2 ||
    (!isChanging && (data.type?.id === 1 ? data.status?.id > 2 : data.decision))

  const conditionMerchant = !isChanging

  const conditionProblemPlace = !isChanging

  const conditionKioskAddress = !isChanging

  const conditionSoftwareProblem = !isChanging

  const conditionComment = !isChanging

  const checkTypeTicketCondition = (typeID) => {
    return typeID !== 1 || typeID !== 2
  }

  return (
    <>
      <Form
        wrapperCol={{
          span: 8
        }}
        layout='vertical'
        onFinish={handleSubmit}
      >
        <Form.Item
          required={!conditionTypeTicket}
          label='Тип обращения'
          extra={touched.typeId && <Error message={errors.typeId} />}
          style={{ fontWeight: conditionTypeTicket ? 500 : 400 }}
        >
          {conditionTypeTicket ? (
            <Label value={activeType} />
          ) : (
            <Select
              placeholder='Выберите тип проблемы'
              optionFilterProp='children'
              disabled={conditionTypeTicket}
              value={values.typeId}
              onChange={handleChangeSelect('typeId')}
            >
              {optionsTypes?.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                )
              })}
            </Select>
          )}
        </Form.Item>

        {values.typeId && (
          <>
            <Form.Item
              required={!conditionProblemPlace}
              label='Мерчант'
              extra={
                touched.merchantId && <Error message={errors.merchantId} />
              }
              style={{ fontWeight: conditionMerchant ? 500 : 400 }}
            >
              {conditionMerchant ? (
                <Label value={getMerchantById(values.merchantId)} />
              ) : (
                <SingleSelect
                  showSearch={true}
                  value={values.merchantId}
                  options={optionsMerchants}
                  disabled={conditionMerchant}
                  onChange={handleChangeSelect('merchantId')}
                />
              )}
            </Form.Item>

            <Form.Item
              label='Проблемное место'
              extra={
                touched.problemPlaceId && (
                  <Error message={errors.problemPlaceId} />
                )
              }
              required={!conditionProblemPlace}
              style={{ fontWeight: conditionProblemPlace ? 500 : 400 }}
            >
              {conditionProblemPlace ? (
                <Label value={getOptionPlaceById(values.problemPlaceId)} />
              ) : (
                <SingleSelect
                  value={values.problemPlaceId}
                  options={optionsPlaces}
                  disabled={conditionProblemPlace}
                  onChange={handleChangeSelect('problemPlaceId')}
                />
              )}
            </Form.Item>
            {values.problemPlaceId === 1 && (
              <Form.Item
                label='Адрес киоска'
                required={!conditionKioskAddress}
                extra={
                  touched.truthKiosk && <Error message={errors.truthKiosk} />
                }
                style={{ fontWeight: conditionKioskAddress ? 500 : 400 }}
              >
                {conditionKioskAddress ? (
                  <Label value={activeKiosk} />
                ) : (
                  <SingleSelect
                    showSearch={true}
                    value={values.truthKiosk}
                    options={optionsKiosks}
                    disabled={conditionKioskAddress}
                    onChange={handleChangeSelect('truthKiosk')}
                  />
                )}
              </Form.Item>
            )}
          </>
        )}

        {checkTypeTicketCondition(values.typeId) && (
          <Form.Item
            required={!conditionSoftwareProblem}
            label='Проблемный модуль ПО'
            extra={touched.softwares && <Error message={errors.softwares} />}
            style={{ fontWeight: conditionSoftwareProblem ? 500 : 400 }}
          >
            {conditionSoftwareProblem ? (
              <Label value={getSoftwaresByListId(values.softwares)} />
            ) : (
              <MultiSelect
                options={optionsSoftwares}
                placeholder='Выберите ПО'
                mode='multiple'
                disabled={conditionSoftwareProblem}
                value={values.softwares}
                onChange={handleChangeSelect('softwares')}
              />
            )}
          </Form.Item>
        )}
        {checkTypeTicketCondition(values.typeId) && (
          <Form.Item
            required={!conditionComment}
            label={'Итоги работы'}
            extra={touched.comment && <Error message={errors.comment} />}
            style={{ fontWeight: conditionComment ? 500 : 400 }}
          >
            {conditionComment ? (
              <Label value={values.comment} />
            ) : (
              <Input.TextArea
                value={values.comment}
                disabled={conditionComment}
                onChange={handleChange('comment')}
                className={styles.answerTextarea}
                rows='6'
              />
            )}
          </Form.Item>
        )}

        {((statusClosedAt ? false : currentUser) || isAdmin) && (
          <ConditionalRender roles={[1, 2]}>
            {!data.type?.id && (
              <Button loading={loading} onClick={handleSubmit} type='primary'>
                Сохранить
              </Button>
            )}
            {(checkTypeTicketCondition(data.type?.id)
              ? data.status?.id >= 2
              : data.type?.id
              ? data.decision !== null
              : false) && (
              <>
                <Button
                  loading={loading}
                  onClick={!isChanging ? handleChanging : handleSubmit}
                  type='primary'
                >
                  {isChanging ? 'Сохранить' : 'Изменить'}
                </Button>
                {isChanging && (
                  <Button onClick={handleCancelEdit} type='default'>
                    Отменить
                  </Button>
                )}
              </>
            )}
          </ConditionalRender>
        )}
      </Form>
    </>
  )
}
