import React, { useCallback, useState } from 'react'
import { Modal, Switch, Button, Input, Row, message } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

import styles from './styles.module.scss'
import { useProfile } from 'hooks/useContext'
import axiosInstance from 'utils/axios'
export const AccessModal = ({
  title,
  onClose,
  isOpen,
  openedArticle,
  onChangeAccess
}) => {
  const { isAdmin } = useProfile()
  const [loading, setLoading] = useState(false)

  const url = `${window.location.origin}/article/${openedArticle?.id}`
  const [messageApi, contextHolder] = message.useMessage()

  const copy = useCallback(() => {
    navigator.clipboard.writeText(url)

    messageApi.open({
      type: 'success',
      content: 'Ссылка скопирована в буфер обмена',
      duration: 2
    })
  }, [url])

  const handlerChangeAccess = async (value) => {
    setLoading(true)
    const res = await axiosInstance.put(`article/access/${openedArticle.id}`, {
      open: value
    })
    onChangeAccess(res.data)

    setLoading(false)
  }

  return (
    <Modal open={isOpen} onCancel={onClose} footer={null}>
      <h1>{title}</h1>
      {openedArticle?.public ? (
        <p>Просматривать могут все в интернете, у кого есть эта ссылка</p>
      ) : (
        <p>
          {isAdmin
            ? 'Разрешить публичный доступ'
            : 'Публичный доступ к статье ограничен правами Администратора ServiceDesk'}
        </p>
      )}

      {isAdmin && (
        <Switch
          onChange={handlerChangeAccess}
          style={{ marginBottom: 20 }}
          checked={openedArticle?.public}
          loading={loading}
        />
      )}

      {openedArticle?.public && (
        <Row className={styles.blockUrl}>
          <Button icon={<LinkOutlined />} onClick={copy} />
          <Input value={url} disabled />
        </Row>
      )}

      {contextHolder}
    </Modal>
  )
}
