import styles from './styles.module.scss'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Typography, Tooltip, Button, Table } from 'antd'
import { useParams } from 'react-router'
import { useMerchant } from 'api/Merchant'
import ConditionalRender from 'components/ConditionalRender'
import { CautionModal } from 'components/CautionModal'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { MerchantModal } from '../MerchantModal'
import { ContactModal } from './ContactModal'
import { MerchantKioskModal } from './KiosksModal'
export const MerchantCard = () => {
  const [data, setData] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [merchantKioskModalOpen, setMerchantKioskModalOpen] = useState(false)
  const [contact, setContact] = useState([])
  const [merchant, setMerchant] = useState([])
  const [deletingData, setDeletingData] = useState({
    modal: false,
    name: '',
    id: null
  })

  const params = useParams()
  const { getMerchantById, deleteMerchantContact, loading } = useMerchant()

  const getData = useCallback(async () => {
    const res = await getMerchantById(params.id)
    setData(res)
  }, [getMerchantById, params.id])

  const handleDeleteContact = async () => {
    try {
      await deleteMerchantContact(deletingData.id)
      getData()
    } catch (e) {
      throw new Error(e)
    } finally {
      setDeletingData({ modal: false, id: null, name: '' })
    }
  }

  const updateDeleteStateHandler = useCallback(
    (contact) => () => {
      setDeletingData({ modal: true, id: contact.id, name: contact.name })
    },
    []
  )

  const closeDeletingModalHandler = () => {
    setDeletingData({ modal: false, id: null, name: '' })
  }

  const handleCloseMerchantKioskModal = () => {
    setMerchantKioskModalOpen(false)
    getData()
  }

  const columns = useMemo(
    () => [
      {
        id: 1,
        title: '№',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone'
      },
      {
        title: 'Должность',
        dataIndex: 'position',
        key: 'position'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Прочее',
        dataIndex: 'other',
        key: 'other'
      },
      {
        id: 2,
        title: '',
        key: 'id',
        dataIndex: 'id',
        render: (text, record) => (
          <div className={styles.tableButtons}>
            <EditOutlined
              className={styles.icons}
              onClick={handleEditContact(record)}
            />
            <DeleteOutlined
              className={styles.icons}
              onClick={updateDeleteStateHandler(record)}
            />
          </div>
        )
      }
    ],
    [updateDeleteStateHandler]
  )

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const handleEditMerchant = () => {
    setMerchant(data)
    setModalOpen(true)
  }

  const handleJustCloseModal = () => {
    setModalOpen(false)
    setMerchant([])
  }

  const handleOnCloseModal = () => {
    handleJustCloseModal()
    getData()
  }

  const handleEditContact = (value) => () => {
    setContact(value)
    setContactModalOpen(true)
  }

  const handleOpenContactModal = () => {
    setContactModalOpen(true)
  }

  const handleJustCloseContactModal = () => {
    setContactModalOpen(false)
    setContact([])
  }

  const handleCloseContactModal = () => {
    handleJustCloseContactModal()
    getData()
  }

  return (
    <div>
      <Typography.Title className={styles.title} level={2}>
        {data.name}
      </Typography.Title>

      <ConditionalRender roles={[1, 2]}>
        <Button
          style={{ float: 'right' }}
          type='primary'
          onClick={handleOpenContactModal}
        >
          Добавить контакт
        </Button>
      </ConditionalRender>

      {/* <Button
        style={{ float: 'right', marginRight: 10 }}
        type='primary'
        onClick={() => setMerchantKioskModalOpen(true)}
      >
        Обновить киоски
      </Button> */}

      <div className={styles.merchant_card}>
        <span>Телефон:</span>
        <span>{data.phone}</span>
        <span>Должность:</span>
        <span>{data.position}</span>
        <span>Эл. почта:</span>
        <span>{data.email}</span>
        <span>Киоски:</span>
        <span>{data.kiosks}</span>
        {data.other && (
          <>
            <span>Прочее:</span>
            <span>{data.other}</span>
          </>
        )}

        <ConditionalRender roles={[1, 2]}>
          <Tooltip
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            placement='top'
            title='Редактировать обращение'
          >
            <EditOutlined
              onClick={handleEditMerchant}
              className={styles.edit_button}
            />
          </Tooltip>
        </ConditionalRender>
      </div>

      <MerchantModal
        merchant={merchant}
        justClose={handleJustCloseModal}
        onClose={handleOnCloseModal}
        isOpen={modalOpen}
      />
      <ContactModal
        contact={contact}
        merchantId={data.id}
        justClose={handleJustCloseContactModal}
        onClose={handleCloseContactModal}
        isOpen={contactModalOpen}
      />

      <Table
        dataSource={data.contacts}
        columns={columns}
        loading={loading}
        pagination={false}
        locale={{ emptyText: 'Контактов нет' }}
        className={styles.table}
        rowClassName={styles.row}
      />

      <CautionModal
        isOpen={deletingData.modal}
        onClose={closeDeletingModalHandler}
        onFinish={handleDeleteContact}
        title={`Вы уверены что хотите удалить контакт: ${deletingData.name}?`}
      />

      <MerchantKioskModal
        isOpen={merchantKioskModalOpen}
        merchantId={data.id}
        onClose={handleCloseMerchantKioskModal}
      />
    </div>
  )
}
