import { Table } from 'antd'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import StatusIndicator from 'components/StatusIndicator'
import { normalizeKiosk } from 'utils/helpers'
import { formatTime, makeColorIndicator } from 'utils/helpers'

import styles from './styles.module.scss'

const AttachTab = ({ data }) => {
  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        width: '5.5%'
      },
      {
        title: 'Дата и время обращения',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value) => formatTime(value)
      },
      {
        title: 'Киоск',
        dataIndex: 'kiosk',
        key: 'kiosk',
        width: '7%',
        render: (value, record) => {
          return record?.merchantKiosk ? (
            <div>{normalizeKiosk(record?.merchantKiosk)}</div>
          ) : (
            <div>{value}</div>
          )
        }
      },
      {
        title: 'Мерч',
        dataIndex: 'merchants',
        key: 'merchants',
        render: (value) =>
          value.length > 1 ? (
            <div>Все</div>
          ) : (
            <div>{value.length ? value[0]?.name : '-'}</div>
          ),
        width: '7%'
      },
      {
        title: 'Описание проблемы',
        dataIndex: 'description',
        key: 'description',
        render: (value) => value,
        width: '40%'
      },
      {
        title: 'Решение',
        dataIndex: 'tempDecision',
        key: 'tempDecision',
        width: '20%'
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (value) => {
          const color = makeColorIndicator(value.name)
          return <StatusIndicator indicator={color} content={value.name} />
        }
      }
    ],
    []
  )

  const handleClickRow = (record) => {
    return {
      onClick: () => history.push(`/admin/ticket/${record.id}`),
      onMouseDown: (e) => {
        if (e.button === 1) window.open(`/admin/ticket/${record.id}`, '_blank')
      }
    }
  }

  const sortedDataSource = data.slice().sort((a, b) => b.id - a.id)

  return (
    <Table
      dataSource={sortedDataSource}
      columns={columns}
      onRow={handleClickRow}
      pagination={false}
      rowClassName={styles.row}
      scroll={{ y: 300, x: 500 }}
    />
  )
}

export default AttachTab
