import * as yup from 'yup'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Form, Input, Button, message, Row } from 'antd'

import Error from 'components/Error'
import { version } from '../../../package.json'
import axios from 'utils/axios'
import { useParams } from 'react-router'

// import 'antd/dist/antd.css'
import styles from './styles.module.scss'

const ResetPassword = () => {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const handleSave = async (value) => {
    const { token } = params
    try {
      if (token) {
        setLoading(true)
        const res = await axios.put(`auth/reset-password/${token}`, {
          password: value.password
        })

        message.success(res?.message)

        await setTimeout(() => window.location.replace('/'), 2000)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        password: '',
        repPass: ''
      },
      validationSchema: yup.object().shape({
        password: yup.string().required('Это поле обязательно'),
        repPass: yup
          .string()
          .required('Это поле обязательно')
          .test('match', 'Пароли не совпадают', function (repPass) {
            if (repPass && this.parent.password !== '') {
              if (repPass === this.parent.password) {
                return true
              } else {
                return false
              }
            }
          })
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.loginContainer}>
        <h1 className={styles.logo}>ServiceDesk</h1>
        <Form onFinish={handleSubmit} layout='vertical' className={styles.form}>
          <Form.Item
            label='Новый пароль'
            extra={touched.password && <Error message={errors.password} />}
          >
            <Input.Password
              value={values.password}
              onChange={handleChange('password')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item
            label='Повторите пароль'
            extra={touched.repPass && <Error message={errors.repPass} />}
          >
            <Input.Password
              value={values.repPass}
              onChange={handleChange('repPass')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Row>
            <Form.Item className={styles.lastBlock}>
              <Button loading={loading} type='primary' htmlType='submit'>
                Сбросить
              </Button>
            </Form.Item>

            <Form.Item className={styles.lastBlock} style={{ marginLeft: 10 }}>
              <Button onClick={() => window.location.replace('/')}>
                Авторизация
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <div className={styles.verison}>
          <span>Версия {version}</span>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
