import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Typography,
  Table,
  Modal,
  Form,
  Input,
  Popconfirm,
  Row
} from 'antd'

import Error from 'components/Error'

import axios from 'utils/axios'
import SingleSelect from 'components/SingleSelect'
import styles from './styles.module.scss'

const { Title } = Typography

const Tags = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const [tag, setTag] = useState([])

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/tag')
      setData(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteTag = useCallback(
    async (tag) => {
      try {
        await axios.delete(`/tag/${tag.id}`)
        getData()
      } catch (e) {
        console.log(e)
      } finally {
        handleJustCloseModal()
      }
    },
    [getData]
  )

  const handleEditTag = (value) => {
    setTag(value)
    setModalOpen(true)
  }

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        id: 1,
        title: '№',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Группа',
        dataIndex: 'group',
        key: 'group',
        render: (text, record) => (
          <div>{record?.tagType ? record?.tagType?.name : 'Группы нет'}</div>
        )
      },
      {
        id: 2,
        title: '',
        key: 'id',
        dataIndex: 'id',
        render: (text, record) => (
          <div className={styles.tableButtons}>
            {/* <EditOutlined
              className={styles.icons}
              onClick={handleEditTag(record)}
            /> */}
            {/* <DeleteOutlined
              className={styles.icons}
              onClick={updateDeleteStateHandler(record)}
            /> */}
          </div>
        )
      }
    ],
    []
  )

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleJustCloseModal = () => {
    setModalOpen(false)
    setTag([])
  }

  const handleOnCloseModal = () => {
    handleJustCloseModal()
    getData()
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список Тегов
        </Title>
        <Button type='primary' onClick={handleOpenModal}>
          Добавить Тег
        </Button>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        rowClassName={styles.row}
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleEditTag(record)
            }
          }
        }}
      />

      <TagModal
        tag={tag}
        justClose={handleJustCloseModal}
        onClose={handleOnCloseModal}
        isOpan={modalOpen}
        onRemove={(data) => handleDeleteTag(data)}
      />
    </div>
  )
}

export default Tags

const TagModal = (props) => {
  const { isOpan, onClose, onRemove, tag, justClose } = props

  const [loading, setLoading] = useState(false)

  const [types, setTypes] = useState([])

  const handleSave = async (value) => {
    try {
      setLoading(true)
      tag?.id
        ? await axios.post(`/tag/${tag.id}`, value)
        : await axios.post('/tag', value)
      resetForm()
      onClose()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        name: tag ? tag.name : '',
        tagTypeId: tag ? tag.tagTypeId : null
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Это поле обязательно'),
        tagTypeId: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const optionsTypes = useMemo(() => {
    return types.map((item) => ({ id: item.id, label: item.name }))
  }, [types])

  const fetchListTagTypes = async () => {
    const res = await axios.get('/tag/types')
    setTypes(res.data)
  }

  useEffect(() => {
    fetchListTagTypes()
  }, [])

  const isEdit = tag?.id

  return (
    <Modal open={isOpan} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          {isEdit ? (
            <Row>
              <Form.Item>
                <Popconfirm
                  title={'Удалить тег'}
                  okText={'Подтвердить'}
                  cancelText={'Отмена'}
                  onConfirm={() => onRemove(tag)}
                >
                  <Button loading={loading} type={'primary'} danger>
                    Удалить
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Row>
          ) : null}
          <Form.Item
            required
            label='Название'
            extra={touched.name && <Error message={errors.name} />}
          >
            <Input
              value={values.name}
              onChange={handleChange('name')}
              autoSize={{ minRows: 7 }}
              onPressEnter={handleSubmit}
            />
          </Form.Item>

          <Form.Item
            required
            label='Группа'
            extra={touched.name && <Error message={errors.name} />}
          >
            <SingleSelect
              options={optionsTypes}
              value={values.tagTypeId}
              onChange={(data) => setFieldValue('tagTypeId', data)}
            />
          </Form.Item>

          <Button loading={loading} type='primary' htmlType='submit'>
            {tag?.name ? 'Изменить' : 'Создать'}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
