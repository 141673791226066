import React, { useState, useEffect } from 'react'
import axios from 'utils/axios'
import styles from '../styles.module.scss'
import NumericInput from 'components/NumericInput'
import { useFormik } from 'formik'
import {
  Button,
  Select,
  Row,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Switch,
  Modal,
  Popconfirm
} from 'antd'
import { useProfile } from 'hooks/useContext'

import * as yup from 'yup'
import RangePicker from 'components/RangePicker'

import * as daysJs from 'dayjs'

const formatttingDate = (datePartParam, timePartParm) => {
  const datePart = datePartParam.format('YYYY-MM-DD')
  const timePart = timePartParm.format('HH:mm:ss')
  const combinedDateTime = `${datePart}T${timePart}`
  return combinedDateTime
}

export default ({
  isOpen,
  onClose,
  onCloseModalAndFectch,
  user,
  users,
  handleUpdateUserColor,
  event,
  onChangeUser
}) => {
  const isEdit = event?.id

  const [loading, setLoading] = useState(false)

  const [mode, setMode] = useState(false)
  const initColor = users?.length ? users[0].color : '#0087FC'
  const [colorHex, setColorHex] = useState(user?.color || initColor)
  const hexString = React.useMemo(() => {
    return typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()
  }, [colorHex, user])

  const { isAdmin } = useProfile()
  const handleChangeColor = (color) => {
    setColorHex(color)
    setFieldValue('color', color.toHexString())
  }

  useEffect(() => {
    if (!user?.color) {
      setColorHex(initColor)
      return
    }

    setColorHex(user?.color)
  }, [user?.color, users])

  const [filter, setFilter] = useState({
    dateStart: daysJs(),
    dateEnd: daysJs()
  })

  const handleChangeDate = (name) => (dayjs) => {
    if (name === 'time') {
      const start = dayjs ? dayjs[0] : null
      const end = dayjs ? dayjs[1] : null

      const s = daysJs(formatttingDate(values.date, start))

      const e = daysJs(formatttingDate(values.date, end))
      setFieldValue('dateStart', s)
      setFieldValue('dateEnd', e)
    }

    if (name === 'date') {
      const date = dayjs ? dayjs : null
      setFieldValue('date', date)
    }
  }

  const handlerChangeMode = (data) => {
    setFieldValue('mode', data)
    setMode(data)
  }

  const validationSchema = yup.object().shape({
    mode: yup.boolean(),
    workday: yup.number().required(),
    weekend: yup.number().required(),
    user: yup.number()
  })

  const handleEditForm = async (values) => {
    const { mode, user, dateStart, dateEnd, weekend, workday, date } = values

    try {
      setLoading(true)

      if (isEdit) {
        await axios.put(`/schedule`, {
          mode,
          user,
          dateStart: formatttingDate(date, dateStart),
          dateEnd: formatttingDate(date, dateEnd),
          weekend,
          workday,
          eventId: event?.id
        })
      } else {
        await axios.post(`/schedule`, {
          mode,
          user,
          dateStart: formatttingDate(date, dateStart),
          dateEnd: formatttingDate(date, dateEnd),
          weekend,
          workday
        })
      }

      onCloseModalAndFectch()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleEditForm(values),
      initialValues: {
        mode: false,
        workday: 5,
        weekend: 2,
        user: isEdit ? user.id : 1,
        dateStart: isEdit ? daysJs(event.start) : filter.dateStart,
        dateEnd: isEdit ? daysJs(event.end) : filter.dateEnd,
        date: isEdit ? daysJs(event.start) : filter.dateStart,
        color: isEdit ? hexString : initColor
      },
      validationSchema,
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, Number(event.target.value))
  }

  const onChangeColor = async (state) => {
    if (!state) {
      await handleUpdateUserColor(hexString, values.user)
    }
  }

  const handleChangeSelect = (name) => (value) => {
    setFieldValue(name, value)
    onChangeUser(value)
  }

  const handleRemoveSchedule = React.useCallback(async () => {
    try {
      setLoading(true)

      const res = await axios.delete(`/schedule/${event.id}`)

      onCloseModalAndFectch()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [event])

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={false}
      title={
        isEdit ? `Редактирование графика работы` : `Создание графика работы`
      }
      destroyOnClose={true}
    >
      <Form onFinish={handleSubmit} layout={'vertical'}>
        {isEdit && (
          <Row>
            {isAdmin && (
              <Form.Item>
                <Popconfirm
                  title={'Удалить режим работы'}
                  okText={'Подтвердить'}
                  cancelText={'Отмена'}
                  onConfirm={() => handleRemoveSchedule()}
                >
                  <Button loading={loading} type={'primary'} danger>
                    Удалить
                  </Button>
                </Popconfirm>
              </Form.Item>
            )}
          </Row>
        )}
        <Row>
          <Col>
            <Select
              placeholder='Пользователь'
              value={values.user}
              className={styles.mb}
              onChange={handleChangeSelect('user')}
              disabled={isEdit}
            >
              {users?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>

          <Col style={{ marginLeft: 10 }}>
            <ColorPicker
              // format={'hex'}
              value={hexString}
              disabled={!isAdmin}
              // color={hexString}
              // defaultValue={values.color}
              onChange={handleChangeColor}
              onOpenChange={onChangeColor}
            />
          </Col>
        </Row>

        <Row className={styles.rangepicker}>
          <DatePicker
            className={styles.datapicker}
            value={values.date}
            onChange={handleChangeDate('date')}
            disabled={!isAdmin}
          />
        </Row>

        <Row className={styles.rangepicker}>
          <RangePicker
            className={styles.datapicker}
            // showTime
            disabled={!isAdmin}
            value={[values.dateStart, values.dateEnd]}
            onChange={handleChangeDate('time')}
            picker='time' // Устанавливаем picker в 'date'
            showTime={{
              hideDisabledOptions: true
            }}
          />
        </Row>

        {!isEdit && (
          <Row>
            <Form.Item style={{ display: 'flex' }} name={'mode'}>
              <div>
                <p>Заполнить на весь месяц</p>
                <Switch
                  onChange={handlerChangeMode}
                  style={{ marginBottom: 20 }}
                  checked={mode}
                />
              </div>
            </Form.Item>
          </Row>
        )}

        {mode && (
          <Row>
            <Col span={11}>
              <Form.Item
                required
                label='Кол-во рабочих дней подряд'
                name={'workday'}
              >
                <NumericInput
                  value={values.workday}
                  onChange={handleChange('workday')}
                  defaultValue={values.workday}
                />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item required label='Кол-во выходных' name={'weekend'}>
                <NumericInput
                  value={values.weekend}
                  onChange={handleChange('weekend')}
                  defaultValue={values.weekend}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {isAdmin && (
          <Row style={{ marginTop: '30px' }}>
            <Form.Item>
              <Button loading={loading} htmlType='submit' type='primary'>
                {isEdit ? 'Cохранить' : 'Создать'}
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
    </Modal>
  )
}
