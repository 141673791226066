import { DeleteOutlined } from '@ant-design/icons'
import { Modal, Result, Button } from 'antd'
import React, { useState } from 'react'
import { useChangeReport } from 'api/Report'
import styles from './styles.module.scss'

const OptionsList = ({ list, onOptionClick, onDeleteClick }) => {
  return (
    <div className={styles.options_container}>
      <ul className={styles.list}>
        {list.map((item, index) => (
          <div key={indexedDB} className={styles.list_position}>
            <li
              className={styles.list_item}
              key={item + index}
              onClick={() => onOptionClick(item)}
            >
              <span>{item.name}</span>
            </li>
            <DeleteOutlined
              key={`delete${item.id}`}
              onClick={() => onDeleteClick(item.id)}
              className={styles.item_delete}
            />
          </div>
        ))}
      </ul>
    </div>
  )
}

const DeleteModal = ({ open, onClose, onDelete }) => {
  return (
    <Modal onCancel={onClose} open={open} footer={false}>
      <Result
        status='warning'
        title='Вы действительно хотите удалить данную настройку'
        extra={
          <div>
            <Button type='primary' onClick={onDelete}>
              Удалить
            </Button>
            <Button type='default' onClick={onClose}>
              Отменить
            </Button>
          </div>
        }
      />
    </Modal>
  )
}

export const Options = ({ onSelect, list, whenDelete }) => {
  const { deleteColumnOption } = useChangeReport()
  const [deleteOption, setDeleteOption] = useState({
    modal: false,
    id: null
  })

  const deleteClickHandler = (id) => {
    setDeleteOption({ modal: true, id })
  }

  const closeModalHandler = () => {
    setDeleteOption({ modal: false, id: null })
  }

  const deleteOptionHandler = async () => {
    await deleteColumnOption(deleteOption.id)
    closeModalHandler()
    whenDelete()
  }

  return (
    list.length > 0 && (
      <>
        <OptionsList
          list={list}
          onOptionClick={onSelect}
          onDeleteClick={deleteClickHandler}
        />

        <DeleteModal
          open={deleteOption.modal}
          onClose={closeModalHandler}
          onDelete={deleteOptionHandler}
        />
      </>
    )
  )
}
