import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
// import 'antd/dist/antd.css'
import 'react-notifications-component/dist/theme.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
