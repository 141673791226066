import * as yup from 'yup'
import { useFormik } from 'formik'

import React from 'react'

import { Button, Modal, Row, Col, Form, Input } from 'antd'

import styles from './styles.module.scss'
import Error from 'components/Error'
import axios from 'utils/axios'

export const UploadReporEmployeesEmailModal = (props) => {
  const { isOpen, justClose, data } = props

  const [loading, setLoading] = React.useState(false)
  const [sent, setSent] = React.useState(false)
  const [envelope, setEnvelope] = React.useState({ from: '', to: '' })

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        email: 'support@roby.market'
      },
      validationSchema: yup.object().shape({
        email: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) =>
    setFieldValue(name, event.target.value)
  const handleSave = async (value, arg) => {
    try {
      setLoading(true)
      const res = await axios.post(`/report/employees-upload-email`, data, {
        params: {
          email: value.email
        }
      })
      // const fromTo = res.data.envelope
      // setEnvelope(fromTo)
      setSent(true)
    } catch (error) {
      console.log('ERROR', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={() => {
        justClose()
        setSent(false)
      }}
    >
      {sent ? (
        <div className={styles.sentMail} onClick={() => setSent(false)}>
          <img src='/images/mail.svg' className={styles.logoSvg} alt='' />
          <div className={styles.sentMailTitle}>
            Отчет успешно отправлен на почту
          </div>
          <span>{envelope.to}</span>
        </div>
      ) : (
        <div className={styles.formContainer}>
          <Form onFinish={handleSubmit} layout='vertical'>
            <Row>
              <Col span={24}>
                <Form.Item
                  required
                  label='Укажите почту на которую отправить отчет'
                  extra={touched.email && <Error message={errors.email} />}
                >
                  <Input
                    value={values.email}
                    onChange={handleChange('email')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button loading={loading} htmlType='submit' type='primary'>
                Выгрузить отчет на почту
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  )
}
