import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Button, Modal, Row, Col, Form, Input } from 'antd'
import InputMask from 'react-input-mask'
import Error from 'components/Error'
import { useMerchant } from 'api/Merchant'
import styles from '../styles.module.scss'

export const ContactModal = (props) => {
  const { isOpen, onClose, contact, merchantId, justClose } = props

  const [loading, setLoading] = useState(false)
  const { createMerchantContact, updateMerchantContact } = useMerchant()

  const handleSave = async (value) => {
    try {
      setLoading(true)
      contact?.id
        ? await updateMerchantContact(contact.id, value)
        : await createMerchantContact(value)
      resetForm()
      onClose()
    } catch (e) {
      // empty
    } finally {
      setLoading(false)
    }
  }

  const closeModalHandler = () => {
    new Promise((resolve) => {
      resetForm()
      setFieldValue('phone', '')
      resolve()
    }).then(() => justClose())
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        name: contact.name || '',
        phone: contact.phone || '',
        position: contact.position || '',
        email: contact.email || '',
        other: contact.other || '',
        merchantId: merchantId
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Это поле обязательно'),
        phone: yup.string().notRequired(),
        position: yup.string().notRequired(),
        email: yup.string().email('Email некорректен').notRequired(),
        other: yup.string().notRequired()
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={closeModalHandler}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Row className={styles.formRow}>
            <Col className={styles.formCol}>
              <Form.Item
                required
                label='Имя'
                extra={touched.name && <Error message={errors.name} />}
              >
                <Input
                  value={values.name}
                  onChange={handleChange('name')}
                  autoSize={{ minRows: 7 }}
                  onPressEnter={handleSubmit}
                  tabIndex={1}
                />
              </Form.Item>

              <Form.Item
                label='Телефон'
                extra={touched.phone && <Error message={errors.phone} />}
              >
                <InputMask
                  value={values.phone}
                  className={styles.inputMask}
                  mask={'+7-(999)-999-99-99'}
                  onChange={handleChange('phone')}
                  tabIndex={3}
                />
              </Form.Item>
            </Col>

            <Col className={styles.formCol}>
              <Form.Item
                label='Эл. почта'
                extra={touched.email && <Error message={errors.email} />}
              >
                <Input
                  value={values.email}
                  onChange={handleChange('email')}
                  autoSize={{ minRows: 7 }}
                  tabIndex={2}
                />
              </Form.Item>

              <Form.Item
                label='Должность'
                extra={touched.position && <Error message={errors.position} />}
              >
                <Input
                  value={values.position}
                  onChange={handleChange('position')}
                  autoSize={{ minRows: 7 }}
                  onPressEnter={handleSubmit}
                  tabIndex={4}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label='Прочее'
            extra={touched.other && <Error message={errors.other} />}
          >
            <Input.TextArea
              value={values.other}
              onChange={handleChange('other')}
              autoSize={{ minRows: 5 }}
              onPressEnter={handleSubmit}
              tabIndex={5}
            />
          </Form.Item>

          <Button loading={loading} type='primary' htmlType='submit'>
            {contact?.name ? 'Изменить' : 'Создать'}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
