import { SearchOutlined, FilterOutlined } from '@ant-design/icons'
import { Input, Button, Space, Checkbox } from 'antd'
import React from 'react'
import styles from './styles.module.scss'

// eslint-disable-next-line no-unused-vars
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm()
}

export const parsePhone = (value) => {
  let phone = value.replace(/[^\d]/g, "")

  if (phone.length >= 11) {
    phone = phone.slice(1)
  }

  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "+7-($1)-$2-$3-$4");
}


const handleReset = (clearFilters, setSelectedKeys, confirm) => {
  clearFilters()
  setSelectedKeys([])
  confirm()
}

export const searchFilter = (dataIndex, filterProps = null) => ({

  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div
      style={{ padding: '10px 5px', display: 'flex', flexDirection: 'column' }}
    >
      {filterProps.type === 'checkbox' && (
        <Space>
          <Checkbox.Group
            onChange={(value) => setSelectedKeys(value)}
            className={styles.checkbox_container}
          >
            {filterProps.options?.map((option, index) => (
              <Checkbox key={index} value={option.text}>
                {option.text}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Space>
      )}
      {filterProps.type === 'text' && (
        <Input
          id={dataIndex}
          value={selectedKeys[0]}
          style={{ maxWidth: '200px', width: '100%' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : '')
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      )}
      <div>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type='primary'
          style={{ marginRight: '5px', borderRadius: '5px' }}
        >
          Найти
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, setSelectedKeys, confirm)}
          style={{ marginTop: '5px', borderRadius: '5px', width: '100px' }}
        >
          Сбросить
        </Button>
      </div>
    </div>
  ),
  onFilter: (value, record) => {


    if(dataIndex === 'clientPhone') {

      const valueString = parsePhone(value.toLowerCase())

      return valueString === parsePhone(record[dataIndex])
      
    }

    return     filterProps.type === 'text'
    ? record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : ''
    : dataIndex === 'merchant' || dataIndex === 'software'
    ? record[dataIndex].split(', ').includes(value) === true
    : record[dataIndex] === value
  }
,
  filterIcon: () =>
    filterProps.type === 'text' ? (
      <SearchOutlined style={{ fontSize: '16px' }} />
    ) : (
      <FilterOutlined style={{ fontSize: '16px' }} />
    )
})
