import { useCallback } from 'react'
import axios from 'utils/axios'

export const useChangeProblem = () => {
  const editInfo = useCallback(async (id, data) => {
    try {
      const res = await axios.put(`/problems/${id}/edit`, data)
      return res
    } catch (e) {
      console.log(e)
    }
  }, [])

  return { editInfo }
}
