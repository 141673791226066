import * as yup from 'yup'
import { useFormik } from 'formik'
import { Modal, Button, Form, Input } from 'antd'
import React from 'react'
import { useChangeProfile } from 'api/Profile/index'
import { useNotification } from 'hooks/useNotification'

import Error from 'components/Error'

import styles from './styles.module.scss'

export const PasswordModal = ({ user, isOpen, onClose }) => {
  const { changePass, loading } = useChangeProfile()

  const { createNotification } = useNotification()

  const handleSave = async (value) => {
    try {
      const res = await changePass(user, value.password)
      createNotification(res, 'success')
      resetForm()
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseModal = () => {
    resetForm()
    onClose()
  }

  const handleChange = (name) => (ev) => {
    setFieldValue(name, ev.target.value)
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        password: '',
        repPass: ''
      },
      validationSchema: yup.object().shape({
        password: yup.string().required('Это поле обязательно'),
        repPass: yup
          .string()
          .required('Это поле обязательно')
          .test('match', 'Пароли не совпадают', function (repPass) {
            if (repPass && this.parent.password !== '') {
              if (repPass === this.parent.password) {
                return true
              } else {
                return false
              }
            }
          })
      }),
      enableReinitialize: true
    })

  return (
    <Modal open={isOpen} footer={null} onCancel={handleCloseModal}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item
            label='Новый пароль'
            extra={touched.password && <Error message={errors.password} />}
          >
            <Input.Password
              value={values.password}
              onChange={handleChange('password')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item
            label='Повторите пароль'
            extra={touched.repPass && <Error message={errors.repPass} />}
          >
            <Input.Password
              value={values.repPass}
              onChange={handleChange('repPass')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Button loading={loading} type='primary' htmlType='submit'>
            Сохранить
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
