import { Modal, Image } from 'antd'
import React from 'react'
import { DownloadOutlined, ZoomInOutlined } from '@ant-design/icons'
import styles from './styles.module.scss'

export const PhotoModal = (props) => {
  const { isOpen, justClose, photoFiles, otherFiles } = props

  const handleDownload = (file) => () => {
    window.open(file)
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        {photoFiles.length > 0 && (
          <div className={styles.photoContainer}>
            {photoFiles?.map((photo, i) => (
              <Image.PreviewGroup key={i}>
                <div key={i} className={styles.imagePreview}>
                  <Image
                    width={80}
                    height={95}
                    preview={{
                      mask: <ZoomInOutlined className={styles.iconZoomIn} />
                    }}
                    src={photo}
                  />
                </div>
              </Image.PreviewGroup>
            ))}
          </div>
        )}

        {otherFiles.length > 0 && (
          <div className={styles.otherFilesContainer}>
            {otherFiles.map((file, i) => (
              <div key={i} className={styles.downloadRow}>
                <span className={styles.fileName}>{`Файл №${i}.${
                  file.split('.')[1]
                }`}</span>
                <DownloadOutlined
                  onClick={handleDownload(file)}
                  className={styles.downloadIcon}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}
