import { useParams } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react'

// import 'antd/dist/antd.css'
import styles from './styles.module.scss'

import axios from 'utils/axios'

import Editor from 'pages/KnowledgeBase/Editor'

const Article = () => {
  const params = useParams()

  const [url, setUrl] = useState(`${window.location.origin}`)

  const [article, setArticle] = useState(null)
  const editorRef = useRef()
  const initArticle = async (id) => {
    const res = (await axios.get(`/public/article/${id}`)).data

    setUrl(`${window.location.origin}/knowledge-base#${res.id}`)

    setArticle(res)
  }

  useEffect(() => {
    initArticle(params.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={styles.header}>
        <img
          src='/images/logo.svg'
          className={styles.logoSvg}
          alt='Логотип Robymarket'
        />
        {article && (
          <div className={styles.number}>
            <a href={url} className={styles.bold}>
              Оригинал
            </a>
          </div>
        )}
      </div>
      <div className={styles.formContainer}>
        <h1 style={{ fontWeight: 'bold' }}>{article?.title}</h1>
        <Editor
          ref={editorRef}
          initialState={article?.editorState || null}
          readOnly={true}
        />
      </div>
    </>
  )
}

export default Article
