import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  Avatar,
  Col,
  Row,
  Table,
  Form,
  Input,
  Button,
  Result,
  Modal,
  Tag
} from 'antd'
import { normalizeKiosk } from 'utils/helpers'
import InputMask from 'react-input-mask'
import { PlusOutlined } from '@ant-design/icons'
import Error from 'components/Error'
import styles from './styles.module.scss'
import { UserOutlined } from '@ant-design/icons'
import axios from 'utils/axios'
import * as yup from 'yup'
import { useFormik } from 'formik'

import MultiSelect from 'components/MultiSelect'
const Label = ({ value }) => {
  return (
    <div className={styles.label} style={{ whiteSpace: 'pre-wrap' }}>
      {value}
    </div>
  )
}

import { useHistory } from 'react-router'

import { formatTime } from 'utils/helpers'

const AttachTab = ({ data }) => {
  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        width: '5.5%'
      },
      {
        title: 'Дата и время обращения',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value) => formatTime(value)
      },

      {
        title: 'Описание проблемы',
        dataIndex: 'description',
        key: 'description',
        render: (value) => value,
        width: '40%'
      },
      {
        title: 'Решение',
        dataIndex: 'tempDecision',
        key: 'tempDecision',
        width: '20%'
      }
    ],
    []
  )

  const handleClickRow = (record) => {
    return {
      onClick: () => window.open(`/admin/ticket/${record.id}`, '_blank')
    }
  }

  return (
    <Table
      dataSource={data}
      columns={columns}
      onRow={handleClickRow}
      pagination={false}
      rowClassName={styles.row}
      scroll={{ y: 300, x: 500 }}
    />
  )
}

export default AttachTab

const validationSchema = yup.object().shape({
  name: yup.string().required('Это поле обязательно'),
  phone: yup.string().required('Это поле обязательно'),
  pans: yup.array()
})

export const Client = ({ data, update }) => {
  const [pans, setPans] = useState([])
  const [tickets, setTickets] = useState([])
  const [kiosks, setKiosks] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalCreateOpen, setModalCreateOpen] = useState(false)
  const { client } = data
  const [isChanging, setIsChanging] = useState(false)

  const isSourceClient = data?.source?.id === 1

  const getPans = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/pan`)
      setPans(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getClientTickets = useCallback(async () => {
    try {
      setLoading(true)

      if (isSourceClient) {
        const res = await axios.get(`/client/${client.id}/tickets`)
        setTickets(res.data)
      } else {
        const merchants = data.merchants.map((item) => '' + item.id)
        // console.log('merchants', merchants)
        const res = await axios.get(`/merchant/tick/tak`, {
          params: { merchantsIds: merchants }
        })

        setTickets(res.data)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getClientKiosks = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/client/${client.id}/kiosks`)
      setKiosks(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditForm = async (value) => {
    try {
      if (isChanging) {
        setLoading(true)
        await axios.put(`/client/${data.client.id}`, value)
        await update()
        setIsChanging(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const initialValues = useMemo(() => {
    if (client) {
      return {
        name: client.name,
        phone: client.phone,
        pans: client.pans?.length > 0 ? client.pans?.map((item) => item.id) : []
      }
    }
  }, [client])

  useEffect(() => {
    getPans()
    getClientTickets()
    getClientKiosks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsPans = useMemo(() => {
    return pans.map((item) => ({ id: item.id, label: item.number }))
  }, [pans])

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleEditForm(values),
      initialValues,
      validationSchema,
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleChangeSelect = (name) => (value) => {
    setFieldValue(name, value)
  }

  const getPansByListId = useCallback(
    (pansIds) => {
      let list = []

      pansIds.map((panId) => {
        optionsPans.some((item) => {
          if (item.id === panId) {
            list.push(item.label)
          }
        })
      })
      return list.join(', ')
    },

    [optionsPans]
  )

  const handleChanging = () => {
    setIsChanging(!isChanging)
  }

  // if (!client) {
  //   return <Result status='404' title='К этому обращению клиент не привязан' />
  // }

  return (
    <div>
      <Row>
        <Col>
          <Avatar size={64} icon={<UserOutlined />} />
        </Col>

        {isSourceClient ? (
          <Col className={styles.colClientTitile}>
            <span className={styles.titleClientPhone}>{client?.phone}</span>
          </Col>
        ) : (
          <Col className={styles.colClientTitile}>
            <span className={styles.titleClientPhone}>
              {data.merchants?.length
                ? data.merchants?.map((item) => item.name).join(', ')
                : 'Не задан'}
            </span>
          </Col>
        )}
      </Row>

      {isSourceClient && (
        <Row>
          {kiosks.length !== 0 && (
            <Form.Item
              label='Проблемные киоски клиента'
              className={styles.panel}
            >
              <div className={styles.tagsPanel}>
                {kiosks.map((kiosk) => (
                  <Tag key={kiosk.id}>{`${normalizeKiosk(kiosk)}`}</Tag>
                ))}
              </div>
            </Form.Item>
          )}
        </Row>
      )}

      {isSourceClient && (
        <Row className={styles.clientForm}>
          <Form layout='vertical' onFinish={handleSubmit}>
            <Form.Item
              label='Имя'
              extra={touched.name && <Error message={errors.name} />}
              style={{ fontWeight: !isChanging ? 500 : 400 }}
            >
              {!isChanging ? (
                <Label value={values?.name} />
              ) : (
                <Input
                  value={values?.name}
                  onChange={handleChange('name')}
                  placeholder='Имя'
                />
              )}
            </Form.Item>

            <Form.Item
              label='PANS Клиента'
              extra={touched.pans && <Error message={errors.pans} />}
              style={{ fontWeight: !isChanging ? 500 : 400 }}
            >
              <div className={styles.pansControl}>
                {!isChanging ? (
                  <Label value={getPansByListId(values?.pans || [])} />
                ) : (
                  <>
                    <MultiSelect
                      options={optionsPans}
                      placeholder='Выберите ПАН'
                      mode='multiple'
                      disabled={!isChanging}
                      value={values?.pans || []}
                      onChange={handleChangeSelect('pans')}
                    />
                    <Button
                      style={{ marginLeft: 10 }}
                      type='primary'
                      shape='circle'
                      icon={<PlusOutlined />}
                      onClick={() => setModalCreateOpen(true)}
                    />
                  </>
                )}
              </div>
            </Form.Item>

            <Button
              loading={loading}
              onClick={!isChanging ? handleChanging : handleSubmit}
              type='primary'
            >
              {isChanging ? 'Сохранить' : 'Изменить'}
            </Button>

            {isChanging && (
              <Button
                onClick={() => {
                  resetForm()
                  handleChanging()
                }}
                type='default'
              >
                Отменить
              </Button>
            )}
          </Form>
        </Row>
      )}

      <Row style={{ marginTop: 20 }}>
        <AttachTab data={tickets} />
      </Row>

      {isSourceClient && (
        <PanModal
          phone={client?.phone}
          onClose={() => setModalCreateOpen(false)}
          isOpen={modalCreateOpen}
          updateData={() => {
            update()
            getPans()
          }}
        />
      )}
    </div>
  )
}

const PanModal = (props) => {
  const { phone, onClose, isOpen, updateData } = props
  const [pan, setPan] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCreatePan = async () => {
    try {
      setLoading(true)
      await axios.post(`/pan/create`, { clientPhone: phone, panNumber: pan })
      setLoading(false)
      onClose()
      updateData()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title='Добавить PAN'
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={
        <Button type='primary' loading={loading} onClick={handleCreatePan}>
          Добавить
        </Button>
      }
    >
      <InputMask
        value={pan}
        alwaysShowMask={true}
        style={{ fontSize: 24, width: 'auto' }}
        className={styles.inputMask}
        mask={'999999\\*\\*\\*\\*\\*\\*9999'}
        onChange={(e) => setPan(e.target.value)}
      />
    </Modal>
  )
}
