import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Typography,
  Table,
  Modal,
  Form,
  Input,
  Popconfirm,
  Row
} from 'antd'

import Error from 'components/Error'

import axios from 'utils/axios'

import styles from './styles.module.scss'

const { Title } = Typography

const Softwares = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const [software, setSoftware] = useState([])

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/software')
      setData(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteSoftware = useCallback(
    async (software) => {
      try {
        await axios.delete(`/software/${software.id}`)
        getData()
      } catch (e) {
        console.log(e)
      } finally {
        handleJustCloseModal()
      }
    },
    [getData]
  )

  const handleEditSoftware = (value) => {
    setSoftware(value)
    setModalOpen(true)
  }

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        id: 1,
        title: '№',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name'
      }
    ],
    []
  )

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleJustCloseModal = () => {
    setModalOpen(false)
    setSoftware([])
  }

  const handleOnCloseModal = () => {
    handleJustCloseModal()
    getData()
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список ПО
        </Title>
        <Button type='primary' onClick={handleOpenModal}>
          Добавить ПО
        </Button>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        rowClassName={styles.row}
        onRow={(record) => {
          return {
            onClick: () => {
              handleEditSoftware(record)
            }
          }
        }}
      />

      <SoftwareModal
        software={software}
        justClose={handleJustCloseModal}
        onClose={handleOnCloseModal}
        isOpan={modalOpen}
        onRemove={handleDeleteSoftware}
      />
    </div>
  )
}

export default Softwares

const SoftwareModal = (props) => {
  const { isOpan, onClose, software, onRemove, justClose } = props

  const [loading, setLoading] = useState(false)

  const isEdit = software?.id

  const handleSave = async (value) => {
    try {
      setLoading(true)
      isEdit
        ? await axios.post(`/software/${software.id}`, value)
        : await axios.post('/software', value)
      resetForm()
      onClose()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        name: software ? software.name : ''
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <Modal open={isOpan} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          {isEdit ? (
            <Row>
              <Form.Item>
                <Popconfirm
                  title={'Удалить ПО'}
                  okText={'Подтвердить'}
                  cancelText={'Отмена'}
                  onConfirm={() => onRemove(software)}
                >
                  <Button loading={loading} type={'primary'} danger>
                    Удалить
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Row>
          ) : null}
          <Form.Item
            required
            label='Название'
            extra={touched.name && <Error message={errors.name} />}
          >
            <Input
              value={values.name}
              onChange={handleChange('name')}
              autoSize={{ minRows: 7 }}
              onPressEnter={handleSubmit}
            />
          </Form.Item>

          <Button loading={loading} type='primary' htmlType='submit'>
            {software?.name ? 'Изменить' : 'Создать'}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
