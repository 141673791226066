import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Button, Modal } from 'antd'
import SingleSelect from 'components/SingleSelect'
import { useRoby } from 'hooks/useContext'
import { useMerchant } from 'api/Merchant'

export const MerchantKioskModal = (props) => {
  const { isOpen, merchantId, onClose } = props

  const [loading] = useState(false)

  const { setKioskToMerchant } = useMerchant()

  const [robyKiosks, setRobyKiosk] = useState([])
  const [robyMerchants, setRobyMerchants] = useState([])
  const [selectedKiosks, setSelectedKiosks] = useState('')
  const [selectedMerchant, setSelectedMerchant] = useState('')

  const resetStates = () => {
    setSelectedMerchant('')
    setSelectedKiosks('')
  }

  const closeModalHandler = () => {
    onClose()
    resetStates()
  }

  // const { fetchJson } = useRoby()

  // const getAllRobyKiosks = useCallback(async () => {
  //   try {
  //     const res = await fetchJson(26)
  //     setRobyKiosk(res.data)
  //   } catch (e) {
  //     throw new Error(e)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const setAllMerch = useCallback(async () => {
  //   let res
  //   try {
  //     res = await fetchJson(18, {
  //       uid: '00000000-0000-0000-0000-000000000000',
  //       name: '',
  //       active: true
  //     })
  //   } catch {
  //     return
  //   }
  //   setRobyKiosk(res.data)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const getAllRobyMerchants = useCallback(async () => {
  //   let res
  //   try {
  //     res = await fetchJson(41)
  //   } catch {
  //     return
  //   }

  //   setRobyMerchants(res.data)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (selectedMerchant) {
      const merchantKiosks = robyKiosks.filter(
        (item) =>
          item.merchantUid.toLowerCase() === selectedMerchant.toLowerCase()
      )
      setSelectedKiosks(merchantKiosks.map((item) => item.number).join(', '))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchant])

  useEffect(() => {
    // getAllRobyKiosks()
    // setAllMerch()
    // getAllRobyMerchants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (value) => {
    setSelectedMerchant(value)
  }

  const merchantOptions = useMemo(() => {
    return robyMerchants.map((item) => ({ id: item.uid, label: item.name }))
  }, [robyMerchants])

  const handleSave = async () => {
    await setKioskToMerchant(merchantId, selectedKiosks)
    closeModalHandler()
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={closeModalHandler}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 25
        }}
      >
        <SingleSelect
          options={merchantOptions}
          style={{ marginBottom: 30 }}
          value={selectedMerchant}
          showSearch={true}
          onChange={handleChange}
        />
        <Button loading={loading} onClick={handleSave} type='primary'>
          Сохранить
        </Button>
      </div>
    </Modal>
  )
}
