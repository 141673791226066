import { List, Avatar } from 'antd'
import React, { useState, useCallback, useRef } from 'react'
import debounce from 'lodash.debounce'
import InputMask from 'react-input-mask'
import styles from './styles.module.scss'
import useOnClickOutside from './hooks/useOnClickOutside'
import axios from 'utils/axios'
import { parsePhone } from 'utils/phone'

import { UserOutlined } from '@ant-design/icons'

const Finder = ({ onFind, onChangePhone, defValue }) => {
  const [value, setValue] = useState(defValue)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [show, setShow] = useState(false)

  const handleSearch = async (value) => {
    if (parsePhone(value).length < 4) return
    setLoading(true)
    const response = await axios.get(`/client?search=${value}`)
    const data = response.data

    if (data?.length) {
      setOptions(data)
      setShow(true)
    } else {
      setShow(false)
      onFind({ name: '', phone: value, pans: [] })
    }

    setLoading(false)
  }

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), [])

  const handleChange = (event) => {
    setValue(event.target.value)
    onChangePhone(event.target.value)
    debouncedChangeHandler(event.target.value)
  }

  const ModalRefFinder = useRef(null)

  useOnClickOutside(ModalRefFinder, () => setShow(false))

  const handleClickOnClient = (client) => {
    setValue(client.phone)
    onFind(client)
    setOptions([])
    setShow(false)
  }

  return (
    <div ref={ModalRefFinder} className={styles.container}>
      <InputMask
        value={value}
        className={styles.inputMask}
        mask={'+7-(999)-999-99-99'}
        onChange={handleChange}
      />

      {show && (
        <div className={styles.modal}>
          <List
            loading={loading}
            itemLayout='horizontal'
            dataSource={options}
            renderItem={(item) => (
              <List.Item
                className={styles.client}
                onClick={() => handleClickOnClient(item)}
              >
                <List.Item.Meta
                  className={styles.photo}
                  avatar={<Avatar size={32} icon={<UserOutlined />} />}
                  title={item.phone}
                  description={item.name}
                />
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  )
}

export default Finder
