import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Typography, Table, Form, Input } from 'antd'
import { useHistory } from 'react-router-dom'
import { CautionModal } from 'components/CautionModal'
import { MerchantModal } from './MerchantModal'
import { useMerchant } from 'api/Merchant'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import debounce from 'lodash.debounce'
import styles from './styles.module.scss'
import ConditionalRender from 'components/ConditionalRender'

const { Title } = Typography

const Merchants = () => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const { getAllMerchants, deleteMerchant } = useMerchant()
  const [data, setData] = useState([])
  const [deletingData, setDeletingData] = useState({
    modal: false,
    name: '',
    id: null
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [merchant, setMerchant] = useState([])

  const history = useHistory()

  const getData = async () => {
    try {
      setLoading(true)
      setData(await getAllMerchants({ q: search }))
    } catch (e) {
      // empty
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteMerchant = async () => {
    try {
      const res = await deleteMerchant(deletingData.id)
      if (res) {
        setData(data.filter((merchant) => merchant.id !== deletingData.id))
      }
    } catch (e) {
      // empty
    } finally {
      setDeletingData({ modal: false, id: null, name: '' })
    }
  }

  const updateDeleteStateHandler = (merchant) => (ev) => {
    ev.stopPropagation()
    setDeletingData({ modal: true, id: merchant.id, name: merchant.name })
  }

  const closeDeletingModalHandler = () => {
    setDeletingData({ modal: false, id: null, name: '' })
  }

  const handleEditSoftware = (value) => {
    // ev.stopPropagation()
    setMerchant(value)
    setModalOpen(true)
  }

  const highlight = (paragraph, sl) => {
    let s = sl
    s = s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const replace = new RegExp(sl, 'gi')

    if (s.length > 0) {
      return paragraph.replace(
        replace,
        `<span class=${styles.highlight}>$&</span>`
      )
    } else {
      return paragraph
    }
  }

  const renderHtmlHighlight = useCallback(
    (paragraph, search) => {
      const s = search.split(' ').filter((item) => item.length !== 0)
      let p = paragraph

      s.forEach((item) => {
        let para = highlight(p, item.trim())
        p = para
      })

      return p
    },
    [search]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Адрес',
        dataIndex: 'address',
        key: 'address',
        render: (text, record) => {
          const kiosk = record.merchantKiosks[0]
          if (kiosk) {
            return `${kiosk.country} ${kiosk.city} ${kiosk.street} ${kiosk.house}`
          }
          return ''
        }
      },
      {
        key: '',
        dataIndex: ''
      }
    ],
    [search]
  )

  const columns2 = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'number',
        key: 'number',
        render: (text, record) => {
          return `Киоск ${text}`
        }
      },
      {
        title: '',
        key: 'address',
        dataIndex: 'address',
        render: (text, record) => {
          return `${record.country} ${record.city} ${record.street} ${record.house}`
        }
      },
      {
        key: '',
        dataIndex: ''
      }
    ],
    [search]
  )

  const handleJustCloseModal = () => {
    setModalOpen(false)
    setMerchant([])
  }

  const handleOnCloseModal = () => {
    handleJustCloseModal()
    getData()
  }

  const rowClickHandler = (record, ...other) => {
    return {
      onClick: () => {
        history.push(`/admin/merchant/${record.id}`)
      },
      onMouseDown: (e) => {
        if (e.button === 1)
          window.open(`/admin/merchant/${record.id}`, '_blank')
      }
    }
  }

  const expandedRowRender = (record) => (
    <Table
      className={styles.table}
      tableLayout={'fixed'}
      dataSource={record.merchantKiosks}
      columns={columns2}
      loading={loading}
      pagination={false}
      rowClassName={styles.row}
      showHeader={false}
    />
  )

  const handleSearch = (value) => {
    setSearch(value)
  }
  const rowExpandable = (record) => record.name !== 'Not Expandable'
  const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), [])

  useEffect(() => {
    getData()
  }, [search])

  const rowKeys = useMemo(() => {
    return data.map((item) => item.id)
  }, [data])

  const openRows = useMemo(() => {
    return search.length > 0
  }, [search])

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список мерчантов
        </Title>
        {/* <Button type='primary' onClick={handleOpenModal}>
          Добавить
        </Button> */}
      </div>

      <div>
        <Form
          wrapperCol={{
            span: 10
          }}
          layout='vertical'
        >
          <Form.Item label='Поиск' className={styles.formItem}>
            <Input
              style={{ width: '250px' }}
              placeholder='Поиск'
              onChange={(e) => {
                debouncedChangeHandler(e.target.value)
              }}
            />
          </Form.Item>
        </Form>
      </div>

      <Table
        expandedRowKeys={openRows ? rowKeys : false}
        className={styles.table}
        tableLayout={'fixed'}
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        rowKey={(record) => record.id}
        // onRow={rowClickHandler}
        onRow={(record) => {
          return {
            onClick: (event) => {
              event.stopPropagation()
              handleEditSoftware(record)
            }
          }
        }}
        rowClassName={styles.row}
        expandRowByClick={true}
        expandable={{
          expandedRowRender,
          rowExpandable,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined
                style={{ fontSize: '14px' }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <DownOutlined
                style={{ fontSize: '14px' }}
                onClick={(e) => onExpand(record, e)}
              />
            )
        }}
      />

      <MerchantModal
        merchant={merchant}
        justClose={handleJustCloseModal}
        onClose={handleOnCloseModal}
        isOpen={modalOpen}
      />

      <CautionModal
        isOpen={deletingData.modal}
        onClose={closeDeletingModalHandler}
        onFinish={handleDeleteMerchant}
        title={`Вы уверены что хотите удалить мерчанта: ${deletingData.name}?`}
      />
    </div>
  )
}

export default Merchants
