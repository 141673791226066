import * as yup from 'yup'
import React from 'react'
import { useFormik } from 'formik'
import { Modal, Button, Form, Input } from 'antd'

import { useChangeProfile } from 'api/Profile'
import { useNotification } from 'hooks/useNotification'

import Error from 'components/Error'

import styles from './styles.module.scss'

export const ChatIdModal = ({ user, isOpen, onClose }) => {
  const { loading, changeChatId } = useChangeProfile()
  const { createNotification } = useNotification()

  const handleSave = async (value) => {
    try {
      await changeChatId(user, value.chatId)
      createNotification('Telegram chat ID успешно изменен', 'success')
      resetForm()
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseModal = () => {
    resetForm()
    onClose()
  }

  const handleChange = (name) => (ev) => {
    setFieldValue(name, ev.target.value)
  }

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        chatId: ''
      },
      validationSchema: yup.object().shape({
        chatId: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  return (
    <Modal open={isOpen} footer={null} onCancel={handleCloseModal}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item
            label='Telegram chat ID'
            extra={touched.chatId && <Error message={errors.chatId} />}
          >
            <Input
              value={values.chatId}
              onChange={handleChange('chatId')}
              autoSize={{ minRows: 7 }}
              onPressEnter={handleSubmit}
            />
          </Form.Item>

          <Button loading={loading} type='primary' htmlType='submit'>
            Сохранить
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
