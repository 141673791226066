import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Table,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  message,
  Row,
  InputNumber
} from 'antd'

import Error from 'components/Error'

import axios from 'utils/axios'

import styles from './styles.module.scss'
import { UnlockOutlined, LockOutlined } from '@ant-design/icons'

const { Title } = Typography

const ACTIONS = {
  EDIT: 'edit',
  BLOCK: 'block'
}

const TicketsTypes = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalBlockOpen, setModalBlockOpen] = useState(false)
  const [type, setType] = useState(null)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/type')
      setData(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Группа',
        dataIndex: 'group',
        key: 'group',
        render: (text) => <span>{text.name}</span>
      },
      {
        id: 2,
        title: 'Статус',
        key: 'id',
        dataIndex: 'id',
        render: (text, record) =>
          record.group.id === 2 ? (
            <div className={styles.tableButtons}>
              {record.blocked ? (
                <LockOutlined className={styles.iconLock} />
              ) : (
                <UnlockOutlined className={styles.iconUnLock} />
              )}
            </div>
          ) : null
      }
    ],
    []
  )

  const handleModalType = (data, action) => {
    setType(data)

    if (action === ACTIONS.EDIT) {
      setModalOpen(true)
    } else {
      setModalBlockOpen(true)
    }
  }

  const handleOpenModal = () => {
    setType(null)
    setModalOpen(true)
  }

  const handleJustCloseModal = (action) => {
    setType(null)

    if (action === ACTIONS.EDIT) {
      setModalOpen(false)
    } else {
      setModalBlockOpen(false)
    }
  }

  const handleOnCloseModal = () => {
    setType(null)
    setModalOpen(false)
    getData()
  }

  const handleOnUpdate = (data) => {
    setData((state) => {
      return state.map((type) => {
        if (type.id === data.id) {
          return data
        }
        return type
      })
    })

    setType(data)
  }

  const handleRemove = (id) => {
    setData((state) => {
      return state.filter((type) => type.id === id)
    })

    setType(data)
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список типов обращений
        </Title>
        <Button type='primary' onClick={handleOpenModal}>
          Добавить новый тип инцидента
        </Button>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        rowClassName={styles.row}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleModalType(record, ACTIONS.EDIT)
            }
          }
        }}
      />
      <TypeModal
        type={type}
        justClose={() => handleJustCloseModal(ACTIONS.EDIT)}
        onClose={() => handleOnCloseModal(ACTIONS.EDIT)}
        update={(data) => handleOnUpdate(data)}
        remove={(data) => handleRemove(data)}
        isOpen={modalOpen}
        data={data}
      />
    </div>
  )
}

export default TicketsTypes

const TypeModal = (props) => {
  const { type, isOpen, onClose, justClose, update, remove, data } = props
  const isEdit = type?.id
  const lastType = data.slice(-1)[0]
  const [loading, setLoading] = useState(false)

  const handleSave = async (value) => {
    try {
      setLoading(true)

      isEdit
        ? await axios.put(`/type/${type.id}`, value)
        : await axios.post('/type', value)
      resetForm()

      onClose()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleBlockType = React.useCallback(async () => {
    try {
      setLoading(true)

      const res = await axios.put(`/type/blocked/${type.id}`, {
        blocked: !type.blocked
      })

      message.success(
        `Тип ${res.data.name} ${
          res.data.blocked ? 'Заблокирован' : 'Разблокирован'
        }`
      )

      update(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [type])

  const handleRemoveType = React.useCallback(async () => {
    try {
      setLoading(true)

      await axios.delete(`/type/${type.id}`)
      remove(type.id)
      resetForm()
      onClose()
      message.success(`Тип удален ${type.name}`)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [type])

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        id: type ? type.id : lastType?.id + 1 || 0,
        name: type ? type.name : '',
        template: type ? type.template : ''
      },
      validationSchema: yup.object().shape({
        id: yup.number().required('Это поле обязательно'),
        name: yup.string().required('Это поле обязательно'),
        template: yup.string()
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }
  const handleChangeNumber = (name) => (event) => {
    setFieldValue(name, event)
  }

  const isIncident = type?.group?.id === 2

  return (
    <Modal open={isOpen} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        <Form style={{ width: '100%' }} layout='vertical'>
          {isEdit && isIncident ? (
            <Row>
              <Form.Item>
                <Popconfirm
                  title={
                    type.blocked
                      ? 'Тип будет разблокирован!'
                      : 'Тип будет заблокирован!'
                  }
                  okText={'Подтвердить'}
                  cancelText={'Отмена'}
                  onConfirm={() => handleBlockType()}
                >
                  <Button loading={loading} type={'primary'}>
                    {!type.blocked ? 'Заблокировать' : 'Разблокировать'}
                  </Button>
                </Popconfirm>
              </Form.Item>
              <Form.Item style={{ marginLeft: 10 }}>
                <Popconfirm
                  title={'Удалить тип'}
                  okText={'Подтвердить'}
                  cancelText={'Отмена'}
                  onConfirm={() => handleRemoveType()}
                >
                  <Button loading={loading} type={'primary'} danger>
                    Удалить
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Row>
          ) : null}

          <Form.Item
            label='Идентификатор'
            extra={touched.id && <Error message={errors.id} />}
          >
            <InputNumber
              disabled={isEdit}
              value={values.id}
              onChange={handleChangeNumber('id')}
            />
          </Form.Item>

          <Form.Item
            label='Наименование типа'
            extra={touched.name && <Error message={errors.name} />}
          >
            <Input
              placeholder='Наименование типа'
              value={values.name}
              onChange={handleChange('name')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          {isIncident && (
            <Form.Item
              label='Шаблон описания от пользователя'
              extra={touched.template && <Error message={errors.template} />}
            >
              <Input.TextArea
                value={values.template}
                onChange={handleChange('template')}
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button loading={loading} type='primary' onClick={handleSubmit}>
              {isEdit ? 'Cохранить' : 'Создать'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
