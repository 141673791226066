import * as yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { useParams } from 'react-router'
import InputMask from 'react-input-mask'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Card,
  Typography,
  Spin,
  Button,
  Form,
  Input,
  Tooltip,
  Modal,
  Row,
  Col,
  Image,
  message,
  Tag,
  Upload,
  Collapse,
  Empty
} from 'antd'
import { normalizeKiosk } from 'utils/helpers'

import { AnalysisTab } from './AnalysisTab'

import { SystemDecisionTab } from './SystemDecisionTab'

import { useHistory as useEventHistory, EVENTS } from 'api/History'
import { History } from './History'

import { MetaModal } from './modals/MetaModal'
import { OwnerTicketModal } from './modals/OwnerTicketModal'
import { ChangeStatusTicketModal } from './modals/ChangeStatusTicketModal'

import Error from 'components/Error'
import SingleSelect from 'components/SingleSelect'
import DatePickerField from 'components/DatePicker'
import StatusIndicator from 'components/StatusIndicator'
import ConditionalRender from 'components/ConditionalRender'

import axios from 'utils/axios'
import {
  formatTime,
  makeColorIndicator,
  getFilesByType,
  isJson,
  tryParseJson
} from 'utils/helpers'

import { useProfile } from 'hooks/useContext'

// import 'antd/dist/antd.css'
import styles from './styles.module.scss'
import {
  EditOutlined,
  MailOutlined,
  FolderOutlined,
  ProfileOutlined,
  ZoomInOutlined,
  DownloadOutlined,
  DeleteFilled,
  UploadOutlined
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const { Panel } = Collapse
const { Title } = Typography

const TicketCard = () => {
  const history = useHistory()
  const [messageApi, contextHolder] = message.useMessage()

  // const { deleteTicket } = useChangeTicket()
  // const { createNotification } = useNotification()

  const params = useParams()
  const [titleKey, setTitleKey] = useState(
    history.location.search === '?chat' ? 'chat' : 'analysis'
  )

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [answerModalOpen, setAnswerModalOpen] = useState(false)
  const [photoModalOpen, setPhotoModalOpen] = useState(false)
  const [metaModalOpen, setMetaModalOpen] = useState(false)

  const [ownerTicketModalOpen, setOwnerTicketModalOpen] = useState(false)
  const [changeStatusTicketModalOpen, setChangeStatusTicketModalOpen] =
    useState(false)

  const { createHistory } = useEventHistory()

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/ticket/${params.id}`)
      const colorIndicator = makeColorIndicator(res.data.status.name)
      setData({ ...res.data, colorIndicator })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [params])

  const handleChangeTab = (key) => {
    setTitleKey(key)
  }

  useEffect(() => {
    getData()
  }, [getData])

  const applyInWork = useCallback(async () => {
    try {
      setButtonLoading(true)
      await axios.put(`/ticket/${params.id}/startWork`)
      await createHistory({
        ticketId: params.id,
        eventId: EVENTS.APPLY_IN_WORK
      })
    } catch (e) {
      console.log(e)
    } finally {
      if (data?.channel?.id) {
        setTitleKey('chat')
      }
      setButtonLoading(false)
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleTakeWork = useCallback(async () => {
    try {
      setButtonLoading(true)
      await axios.put(`/ticket/${params?.id}/delegate`)
      getData()
    } catch (e) {
      console.log(e)
    } finally {
      setButtonLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeTicket = useCallback(async () => {
    try {
      setButtonLoading(true)
      await axios.put(`/ticket/${params.id}/closeTicket`)

      await createHistory({
        ticketId: params.id,
        eventId: EVENTS.CLOSE_APPEAL
      })

      getData()
    } catch (e) {
      console.log(e)
    } finally {
      setButtonLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabList = [
    {
      key: 'analysis',
      tab: 'Работа с инцидентом'
    },
    // {
    //   key: 'comments',
    //   tab: 'Комментарии'
    // },
    // {
    //   key: 'chat',
    //   tab: 'Чат с пользователем'
    // },
    {
      key: 'systemDecision',
      tab: 'Системная проблема'
    },
    {
      key: 'history',
      tab: 'История'
    }
    // {
    //   key: 'client',
    //   tab: 'Клиент'
    // }
  ]
  const tabListWithSys = [
    {
      key: 'analysis',
      tab: 'Работа с инцидентом'
    },
    // {
    //   key: 'decision',
    //   tab: 'Временное решение'
    // },
    {
      key: 'systemDecision',
      tab: 'Системная проблема'
    },
    // {
    //   key: 'comments',
    //   tab: 'Комментарии'
    // },
    // {
    //   key: 'chat',
    //   tab: 'Чат с пользователем'
    // },
    {
      key: 'history',
      tab: 'История'
    }
    // {
    //   key: 'client',
    //   tab: 'Клиент'
    // }
  ]

  const contentList = {
    analysis: <AnalysisTab data={data} update={getData} />,
    // decision: <DecisionTab data={data} update={getData} />,
    systemDecision: <SystemDecisionTab data={data} update={getData} />,
    // comments: <Comments data={data} update={getData} />,
    // chat: <Chat data={data} update={getData} />,
    history: <History data={data} />
    // client: <Client data={data} update={getData} />
  }

  const handleToggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleJustCloseModal = () => {
    setModalOpen(false)
  }

  const handleCloseModal = () => {
    handleJustCloseModal()
    getData()
  }

  const handleMailClick = () => {
    setAnswerModalOpen(true)
  }

  const handlePhotoClick = () => {
    setPhotoModalOpen(true)
  }

  const handleJustCloseAnswerModal = () => {
    setAnswerModalOpen(false)
  }

  const handleCloseAnswerModal = () => {
    handleJustCloseAnswerModal()
    getData()
  }

  const handleJustClosePhotoModal = () => {
    setPhotoModalOpen(false)
  }

  const handleMetaClick = () => {
    setMetaModalOpen(true)
  }

  const handleJustCloseMetaModal = () => {
    setMetaModalOpen(false)
  }

  const handleDelegate = async () => {
    setOwnerTicketModalOpen(true)
  }

  const handleCloseOwnerTicketModalOpen = () => {
    setOwnerTicketModalOpen(false)
  }

  const handleSaveOwnerTicket = async (delegateUser) => {
    setOwnerTicketModalOpen(false)

    await createHistory({
      ticketId: params.id,
      eventId: EVENTS.TRASFER_ANOTHER_EMPLOYEE,
      meta: delegateUser
    })

    messageApi.open({
      type: 'success',
      content: 'Обращение делегировано'
    })
    getData()
  }

  const handleChangeStatusTicket = async (statusId) => {
    setChangeStatusTicketModalOpen(false)
    getData()
  }

  const { isAdmin } = useProfile()

  return (
    data && (
      <div>
        <div className={styles.topContainer}>
          <div className={styles.leftInfo}>
            <Title className={styles.title} level={2}>
              Инцидент №{data?.id}
            </Title>
            <div className={styles.leftInfoType}>{data?.type?.name}</div>
          </div>

          <ConditionalRender roles={[1, 2]}>
            <div className={styles.ticketTopPanel}>
              {data?.user && data?.status?.id !== 4 && (
                <Button
                  onClick={handleDelegate}
                  type='primary'
                  className={styles.applyButton}
                >
                  Делегировать инцидент
                </Button>
              )}

              {!data?.user && data?.status?.id !== 4 && (
                <Button
                  loading={buttonLoading}
                  type='primary'
                  className={styles.applyButton}
                  onClick={applyInWork}
                >
                  Взять в работу
                </Button>
              )}

              {contextHolder}

              {isAdmin && (
                <Button
                  onClick={() => setChangeStatusTicketModalOpen(true)}
                  type='primary'
                  className={styles.applyButton}
                >
                  Изменить статус инцидента
                </Button>
              )}

              {data?.comment?.trim()?.length > 0 && data?.status.id === 3 && (
                <Button
                  loading={buttonLoading}
                  type='primary'
                  className={styles.applyButton}
                  // disabled={data.decision === null}
                  onClick={closeTicket}
                >
                  Закрыть инцидент
                </Button>
              )}
            </div>
          </ConditionalRender>

          {/* {data?.clientPhone === null && data?.clientName === null && (
            <DeleteOutlined
              className={styles.deleteButtonIcon}
              onClick={handleDeleteTicket}
            />
          )} */}

          <div className={styles.rightInfoContainer}>
            <Title level={2}>
              <StatusIndicator
                content={data?.status?.name}
                indicator={data?.colorIndicator}
              />
            </Title>
          </div>
        </div>

        <Title className={styles.currentUser} level={4}>
          {data?.user?.name}
        </Title>

        <div>
          {loading ? (
            <Spin />
          ) : (
            <UserCard
              data={data}
              modalClick={handleToggleModal}
              mailClick={handleMailClick}
              photoClick={handlePhotoClick}
              metaClick={handleMetaClick}
            />
          )}
        </div>

        {data?.status?.id > 1 && (
          <Card
            style={{ width: '100%' }}
            tabList={
              data?.type?.id === 1 ||
              data?.type?.id === 4 ||
              data?.type?.id === 5 ||
              data?.type?.id === 6 ||
              data?.type?.id === 7 ||
              data?.type?.id === 8 ||
              data?.type?.id === 9 ||
              data?.type?.id === 10
                ? tabListWithSys
                : tabList
            }
            activeTabKey={titleKey}
            onTabChange={handleChangeTab}
          >
            {contentList[titleKey]}
          </Card>
        )}

        <ModalEdit
          isOpen={modalOpen}
          onClose={handleCloseModal}
          justClose={handleJustCloseModal}
          data={data}
        />
        <ModalAnswer
          isOpen={answerModalOpen}
          onClose={handleCloseAnswerModal}
          justClose={handleJustCloseAnswerModal}
          id={data?.id}
        />

        <OwnerTicketModal
          isOpen={ownerTicketModalOpen}
          onClose={handleCloseOwnerTicketModalOpen}
          saveAndClose={handleSaveOwnerTicket}
          ticketId={data?.id}
          currentUserId={data?.user?.id}
        />

        <ChangeStatusTicketModal
          isOpen={changeStatusTicketModalOpen}
          onClose={() => setChangeStatusTicketModalOpen(false)}
          saveAndClose={handleChangeStatusTicket}
          ticketId={data?.id}
          currentTicketId={data.status.id}
        />

        <PhotoModal
          isOpen={photoModalOpen}
          justClose={handleJustClosePhotoModal}
        />

        {data?.metadata && (
          <MetaModal
            isOpen={metaModalOpen}
            onClose={handleJustCloseMetaModal}
            meta={tryParseJson(data?.metadata)}
            isJson={isJson(data?.metadata) ? true : false}
          />
        )}
      </div>
    )
  )
}

export default TicketCard

const UserCard = (props) => {
  const { data, modalClick, mailClick, photoClick, metaClick } = props
  const { user } = useProfile()
  const handleEditInforamtion = () => {
    modalClick()
  }

  const handleSendAnswer = () => {
    mailClick()
  }

  const handleShowPhoto = () => {
    photoClick()
  }

  const handleMetaClick = () => {
    metaClick()
  }

  const currentUser = data?.user?.id === user.id

  const isFiles = data?.photo?.length > 0
  return (
    <Card className={styles.infoCard}>
      <ul className={styles.clientCardList}>
        {/* <li className={styles.clientCardListItem}>
          Канал обращения:
          <p>{data.channel?.name}</p>
        </li> */}
        <li className={styles.clientCardListItem}>
          Источник:
          <p>{data?.client?.name || data.clientName}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Телефон:
          <p>{data?.client?.phone || data?.clientPhone || 'Нет информации'}</p>
        </li>
        {/* <li className={styles.clientCardListItem}>
          Маскированные номера карт клиента:
          <p>
            {data?.client?.pans.length
              ? data?.client?.pans.map((pan) => {
                  return <Tag key={pan.id}>{pan.number}</Tag>
                })
              : 'Нет информации'}
          </p>
        </li> */}

        <li className={styles.clientCardListItem}>
          Email:
          <p>{data.clientEmail || 'Нет информации'}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Место проблемы от пользователя:
          <p>
            {data.merchantKiosk
              ? normalizeKiosk(data.merchantKiosk)
              : 'Нет информации'}
          </p>
        </li>
        <li className={styles.clientCardListItem}>
          Дата и время обращения:
          <p>
            {data.formFilledDate
              ? formatTime(data.formFilledDate)
              : 'Нет информации'}
          </p>
        </li>
        <li className={styles.clientCardListItem}>
          Дата и время проявления проблемы:
          <p>
            {data.detectedAt ? formatTime(data.detectedAt) : 'Нет информации'}
          </p>
        </li>
        <li className={styles.clientCardListItem}>
          Описание инцидента:
          <p>{data.description}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Место проблемы:
          <p>{data.problemPlace?.name || 'Нет информации'}</p>
        </li>
        <li className={styles.clientCardListItem}>
          Киоск:
          <p>
            {data?.problemPlace?.id === 1
              ? data.merchantKiosk
                ? normalizeKiosk(data.merchantKiosk)
                : data?.kiosk
              : 'Нет информации'}
          </p>
        </li>
        <li className={styles.clientCardListItem}>
          Мерчант:
          <p>
            {data.merchants?.length
              ? data.merchants?.map((item) => item.name).join(', ')
              : 'Не задан'}
          </p>
        </li>
      </ul>
      <ConditionalRender roles={[1, 2]}>
        {(data.tempDecision || data.decision) && data.answer === null && (
          <Tooltip
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            placement='top'
            title='Ответить пользователю'
          >
            <MailOutlined
              onClick={handleSendAnswer}
              className={styles.mailIcon}
            />
          </Tooltip>
        )}
        {data.metadata && data.startedWorkAt && (
          <Tooltip
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            placement='top'
            title='Мета-информация'
          >
            <ProfileOutlined
              className={styles.metaButton}
              onClick={handleMetaClick}
            />
          </Tooltip>
        )}
        {!data.closedAt && currentUser && (
          <Tooltip
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            placement='top'
            title='Редактировать обращение'
          >
            <EditOutlined
              onClick={handleEditInforamtion}
              className={styles.editButton}
            />
          </Tooltip>
        )}
      </ConditionalRender>
      <Tooltip
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
        placement='top'
        title='Прикрепленные файлы'
      >
        <FolderOutlined
          onClick={handleShowPhoto}
          className={`${styles.photoButton} ${isFiles ? styles.marker : ''}`}
        />
      </Tooltip>
    </Card>
  )
}

const editValidationSchema = yup.object().shape({
  clientName: yup.string().required('Это поле обязательно'),
  clientPhone: yup
    .string()
    .required('Это поле обязательно')
    .matches(
      /^(\+7|7|8)?[\s/-]?\(?[489][0-9]{2}\)?[\s/-]?[0-9]{3}[\s/-]?[0-9]{2}[\s/-]?[0-9]{2}$/,
      'Неверный формат'
    ),
  clientEmail: yup.string().notRequired().email('Неверный формат'),
  problemPlaceId: yup.string().required('Это поле обязательно'),
  kiosk: yup.string().when('problemPlaceId', {
    is: '1',
    then: yup.string().required('Это поле обязательно')
  }),
  merchantId: yup.string().notRequired(),
  detectedAt: yup.string().required('Это поле обязательно'),
  description: yup.string().required('Это поле обязательно')
})

const ModalEdit = (props) => {
  const { isOpen, justClose, data, onClose } = props

  const { createHistory } = useEventHistory()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [merchants, setMerchants] = useState([])
  const [problemPlace, setProblemPlace] = useState([])

  const initialValues = useMemo(
    () => ({
      clientName: data.clientName ? data.clientName : '',
      clientPhone: data.clientPhone ? data.clientPhone : '',
      clientEmail: data.clientEmail ? data.clientEmail : '',
      problemPlaceId: data.problemPlace?.id || '',
      kiosk: data.kiosk ? data.kiosk : '',
      merchantId: data.merchants?.length
        ? data.merchants.length > 1
          ? 0
          : data.merchants[0]?.id
        : '',
      detectedAt:
        data.detectedAt !== 'Invalid date' ? moment(data.detectedAt) : null,
      description: data.description || ''
    }),
    [data]
  )

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues,
      validationSchema: editValidationSchema,
      enableReinitialize: true
    })

  const getMerchants = useCallback(async () => {
    const res = await axios.get('/merchant')
    setMerchants(res.data)
  }, [])

  const getProblemPlace = useCallback(async () => {
    const res = await axios.get('/public/ticket/problemplace')
    setProblemPlace(res.data)
  }, [])

  useEffect(() => {
    getMerchants()
    getProblemPlace()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsMerchants = useMemo(() => {
    const merchant = merchants.map((item) => ({
      id: item.id,
      label: item.name
    }))
    merchant.unshift({ id: 0, label: 'Все' })
    return merchant
  }, [merchants])

  const optionsPlaces = useMemo(() => {
    return problemPlace.map((item) => ({ id: item.id, label: item.name }))
  }, [problemPlace])

  const handleSave = useCallback(
    async (value) => {
      try {
        setLoading(true)
        value.detectedAt = moment(value.detectedAt).format(
          'YYYY-MM-DD HH:mm:ss.SSSZZ'
        )

        await axios.put(`/ticket/${data.id}/changeData`, value)
        await createHistory({
          ticketId: data.id,
          eventId: EVENTS.EDIT_APPEAL
        })
        resetForm()
        onClose()
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [data, onClose, resetForm]
  )

  const handleCloseModal = () => {
    resetForm()
    justClose()
  }

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleChangeSelect = (name) => (value) => {
    setFieldValue(name, value)
  }

  const handleChangeDate = (name) => (value) => {
    setFieldValue(name, moment(value))
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={handleCloseModal}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Row className={styles.formRow}>
            <Col className={styles.formCol}>
              <Form.Item
                required
                label='Источник'
                extra={
                  touched.clientName && <Error message={errors.clientName} />
                }
              >
                <Input
                  value={values.clientName}
                  onChange={handleChange('clientName')}
                />
              </Form.Item>
              <Form.Item
                label='Email'
                extra={
                  touched.clientEmail && <Error message={errors.clientEmail} />
                }
              >
                <Input
                  value={values.clientEmail}
                  onChange={handleChange('clientEmail')}
                />
              </Form.Item>
            </Col>
            <Col className={styles.formCol}>
              <Form.Item
                required
                label='Телефон источника'
                extra={
                  touched.clientPhone && <Error message={errors.clientPhone} />
                }
              >
                <InputMask
                  value={values.clientPhone}
                  className={styles.inputMask}
                  mask={'+7-(999)-999-99-99'}
                  onChange={handleChange('clientPhone')}
                />
              </Form.Item>
              <Form.Item
                required
                label='Место проблемы'
                extra={
                  touched.problemPlaceId && (
                    <Error message={errors.problemPlaceId} />
                  )
                }
              >
                <SingleSelect
                  options={optionsPlaces}
                  value={values.problemPlaceId}
                  onChange={handleChangeSelect('problemPlaceId')}
                />
              </Form.Item>
            </Col>
          </Row>

          {values.problemPlaceId === 1 && (
            <Form.Item
              label='Место проявления проблемы'
              extra={touched.kiosk && <Error message={errors.kiosk} />}
            >
              <Input
                value={values.kiosk}
                placeholder='Адерс/№ киоска'
                onChange={handleChange('kiosk')}
              />
            </Form.Item>
          )}

          <Form.Item
            required
            label='Описание от пользователя'
            extra={
              touched.description && <Error message={errors.description} />
            }
          >
            <Input.TextArea
              value={values.description}
              onChange={handleChange('description')}
              autoSize={{ minRows: 5 }}
            />
          </Form.Item>

          <Row className={styles.formRow}>
            <Col className={styles.formCol}>
              <Form.Item
                required
                label='Дата и время обнаружения'
                extra={
                  touched.detectedAt && <Error message={errors.detectedAt} />
                }
              >
                <DatePickerField
                  selected={values.detectedAt?._d}
                  className={styles.datePick}
                  timeIntervals={30}
                  onChange={handleChangeDate('detectedAt')}
                />
              </Form.Item>
            </Col>

            <Col className={styles.formCol}>
              <Form.Item
                label='Мерчант'
                extra={
                  touched.merchantId && <Error message={errors.merchantId} />
                }
              >
                <SingleSelect
                  showSearch={true}
                  value={values.merchantId}
                  options={optionsMerchants}
                  onChange={handleChangeSelect('merchantId')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button loading={loading} type='primary' htmlType='submit'>
            Сохранить
          </Button>
        </Form>
      </div>
    </Modal>
  )
}

const ModalAnswer = (props) => {
  const { isOpen, onClose, justClose, id } = props
  const { createHistory } = useEventHistory()
  const [loading, setLoading] = useState(false)

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        answer: ''
      },
      validationSchema: yup.object().shape({
        answer: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleSave = useCallback(
    async (value) => {
      try {
        setLoading(true)
        await axios.put(`/ticket/${id}/sendAnswer`, value)

        await createHistory({
          ticketId: id,
          eventId: EVENTS.REPLY_USER
        })
        resetForm()
        onClose()
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [id, onClose, resetForm]
  )

  return (
    <Modal open={isOpen} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        <Form
          style={{ width: '100%' }}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item
            label='Ответ клиенту'
            extra={touched.answer && <Error message={errors.answer} />}
          >
            <Input.TextArea
              value={values.answer}
              onChange={handleChange('answer')}
              className={styles.answerTextarea}
              rows='6'
            />
          </Form.Item>
          <Button loading={loading} type='primary' htmlType='submit'>
            Отправить
          </Button>
        </Form>
      </div>
    </Modal>
  )
}

const PhotoModal = (props) => {
  const { isOpen, justClose } = props

  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: ''
  })

  const [loading, setLoading] = useState(false)

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        photo: undefined
      },
      validationSchema: yup.object().shape({
        photo: yup.mixed().notRequired().yup
      }),
      enableReinitialize: true
    })

  const params = useParams()
  const [messageApi, contextHolder] = message.useMessage()

  const [photoFiles, setPhotoFiles] = useState([])
  const [otherFiles, setOtherFiles] = useState([])

  const getFiles = useCallback(async () => {
    try {
      const res = await axios.get(`/ticket/${params.id}/files`)
      setPhotoFiles(res.data.photo)
      setOtherFiles(getFilesByType(res.data.photo, false))
    } catch (e) {
      console.log(e)
    }
  }, [params.id])

  useEffect(() => {
    if (!photoFiles.length) {
      getFiles()
    }
    if (!otherFiles.length) {
      getFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownload = (file) => () => {
    window.open(file)
  }

  const confirm = (photo) => {
    Modal.confirm({
      title: 'Удалить фото?',
      content: 'Это фото будет удалено безвозвратно',
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        handleDeleteImage(photo)
      }
    })
  }
  const handleSave = useCallback(async (value) => {
    try {
      setLoading(true)
      const data = value
      const now = moment().format('YYYY-MM-DD HH:mm:ss.SSSZZ')
      data.formFilledDate = now
      data.detectedAt = moment(value.detectedAt).format(
        'YYYY-MM-DD HH:mm:ss.SSSZZ'
      )
      const formData = new FormData()

      for (let key in value) {
        if (key === 'photo') {
          if (value[key]) {
            for (let i = 0; i < value[key].length; i++) {
              formData.append(key, value[key][i].originFileObj)
            }
          } else {
            formData.append(key, value[key])
          }
        } else if (key === 'detectedAt') {
          formData.append(
            key,
            moment(value[key]).format('YYYY-MM-DD HH:mm:ss.SSSZZ')
          )
        } else {
          formData.append(key, value[key])
        }
      }

      // handleCloseModal()
      // history.push(`/admin/ticket/${res.data.id}`)

      const res = await axios.post(
        `/ticket/ticket-photo/${params.id}`,
        formData
      )
      setPhotoFiles(res.data.photo)
      setOtherFiles(getFilesByType(res.data.photo, false))
      resetForm({ values: { photo: [] } })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancel = () => setPreview({ ...preview, visible: false })

  const handleChangePhoto = (name) => (value) => {
    setFieldValue(name, value.fileList)
  }

  const handleDeleteImage = async (photo) => {
    const { id } = photo

    try {
      const res = await axios.delete(`/ticket/ticket-photo/${params.id}`, {
        data: {
          photoId: id,
          ticketId: params.id
        }
      })

      setPhotoFiles(res.data.photo)
      setOtherFiles(getFilesByType(res.data.photo, false))
    } catch (e) {
      console.log(e)
    }
  }

  const handlerErrorMessage = () => {
    messageApi.open({
      type: 'error',
      content: 'Загружаемый файл больше допустимого размера 5 мегабайт',
      duration: 8
    })
  }

  const handlePreview = (file) => {
    if (file.size > 600000) {
      return false
    }
    setPreview({
      visible: true,
      image: file.thumbUrl,
      title: file.name
    })
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        {photoFiles.length > 0 && (
          <div className={styles.photoContainer}>
            {photoFiles?.map((photo, i) => (
              <Image.PreviewGroup key={i}>
                <div key={i} className={styles.imagePreview}>
                  <span
                    onClick={() => {
                      confirm(photo)
                    }}
                    className={styles.deleteImageButton}
                  >
                    <DeleteFilled className={styles.colorDelete} />
                  </span>
                  <Image
                    width={80}
                    height={95}
                    preview={{
                      mask: <ZoomInOutlined className={styles.iconZoomIn} />
                    }}
                    src={photo.path}
                  />
                </div>
              </Image.PreviewGroup>
            ))}
          </div>
        )}
        {otherFiles.length > 0 && (
          <div className={styles.otherFilesContainer}>
            {otherFiles.map((file, i) => (
              <div key={i} className={styles.downloadRow}>
                <span className={styles.fileName}>{`Файл №${i}.${
                  file.split('.')[1]
                }`}</span>
                <DownloadOutlined
                  onClick={handleDownload(file)}
                  className={styles.downloadIcon}
                />
              </div>
            ))}
          </div>
        )}

        {!photoFiles.length && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'Нет файлов'}
          />
        )}

        <Collapse>
          <Panel header='Загрузить файлы' key='1'>
            <Form onFinish={handleSubmit} layout='vertical'>
              <Form.Item
                label='Файлы'
                extra={touched.photo && <Error message={errors.photo} />}
              >
                <Upload
                  // accept='.png,.jpg,.jpeg'
                  listType='picture'
                  fileList={values.photo}
                  onChange={handleChangePhoto('photo')}
                  onPreview={handlePreview}
                  supportServerRender={null}
                  multiple
                  maxCount={5}
                  beforeUpload={(file) => {
                    if (file.size > 600000) {
                      handlerErrorMessage('error')
                      return true
                    }
                    return false
                  }}
                >
                  <Button icon={<UploadOutlined />}>Загрузить файлы</Button>
                </Upload>

                <div className={styles.notif}>
                  <Tag>Максимальное количество файлов 5</Tag>
                  <Tag>Максимальный размер файла 5МБ</Tag>
                </div>
              </Form.Item>

              {values.photo && (
                <Form.Item wrapperCol={{ offset: 9 }}>
                  <Button loading={loading} htmlType='submit' type='primary'>
                    Загрузить
                  </Button>
                </Form.Item>
              )}
            </Form>
            <Modal
              open={preview.visible}
              title={preview.title}
              onCancel={handleCancel}
              footer={null}
            >
              <img
                alt={preview.title}
                style={{ width: '100%' }}
                src={preview.image}
              />
            </Modal>
          </Panel>
        </Collapse>
      </div>
    </Modal>
  )
}
