import { Form, Input } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import styles from '../styles.module.scss'

export const templateReport = (
  text,
  {
    rebootKiosk,
    checkingWork,
    other,
    period,
    viewReceiptsMerchants,
    fillingMerchantTable,
    additionGoodsMerchants
  }
) => {
  return (
    text +
    `
 4. Работа с киосками:
 а. Перезагрузка киоска ${rebootKiosk || '-'}
 б. Проверка работы замка/сканера/экрана${checkingWork || '-'}
 в. Другое ${other || '-'}
    
 5. Просмотр чеков:
 а. Период ${period || '-'}
 б. Мерчанты ${viewReceiptsMerchants || '-'}
    
 6. Заполнение таблицы мерчантов:
 а. Мерчанты ${fillingMerchantTable || '-'}
    
 7. Досписание товаров:
 а. Мерчанты ${additionGoodsMerchants || '-'}
    `
  )
}

export const FormReport = ({ onChangeValues }) => {
  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleSave = (value) => {}

  const { values, setFieldValue } = useFormik({
    onSubmit: (values) => handleSave(values),
    initialValues: {
      rebootKiosk: '-',
      checkingWork: '-',
      other: '-',
      period: '-',
      viewReceiptsMerchants: '-',
      fillingMerchantTable: '-',
      additionGoodsMerchants: '-'
    },
    enableReinitialize: true
  })

  useEffect(() => {
    onChangeValues(values)
  }, [values])

  return (
    <div className={styles.reportForm}>
      <Form>
        <div>4. Работа с киосками:</div>

        <Form.Item
          label='а. Перезагрузка киоска'
          className={styles.formTextArea}
        >
          <Input.TextArea
            minLength={1}
            value={values.rebootKiosk}
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChange('rebootKiosk')}
          />
        </Form.Item>

        <Form.Item label='б. Проверка работы' className={styles.formTextArea}>
          <Input.TextArea
            minLength={1}
            value={values.checkingWork}
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChange('checkingWork')}
          />
        </Form.Item>
        <Form.Item label='в. Другое' className={styles.formTextArea}>
          <Input.TextArea
            minLength={1}
            value={values.other}
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChange('other')}
          />
        </Form.Item>

        <div>5. Просмотр чеков:</div>

        <Form.Item label='а. Период' className={styles.formTextArea}>
          <Input.TextArea
            minLength={1}
            value={values.period}
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChange('period')}
          />
        </Form.Item>

        <Form.Item label='б. Мерчанты' className={styles.formTextArea}>
          <Input.TextArea
            minLength={1}
            value={values.viewReceiptsMerchants}
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChange('viewReceiptsMerchants')}
          />
        </Form.Item>
        <div>6. Заполнение таблицы мерчантов:</div>
        <Form.Item label='a. Мерчанты' className={styles.formTextArea}>
          <Input.TextArea
            minLength={1}
            value={values.fillingMerchantTable}
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChange('fillingMerchantTable')}
          />
        </Form.Item>
        <div>7. Досписание товаров: </div>
        <Form.Item label='a. Мерчанты' className={styles.formTextArea}>
          <Input.TextArea
            minLength={1}
            value={values.additionGoodsMerchants}
            autoSize={{ minRows: 1, maxRows: 10 }}
            onChange={handleChange('additionGoodsMerchants')}
          />
        </Form.Item>
      </Form>
    </div>
  )
}
