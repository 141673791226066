import React from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Upload,
  message,
  Tag,
  Space,
  Popover,
  Tabs
} from 'antd'

import { useEffect, useMemo, useState, useCallback } from 'react'

import { AndroidOutlined, AppleOutlined } from '@ant-design/icons'

import Error from 'components/Error'
import DatePicker from 'components/DatePicker'
import SingleSelect from 'components/SingleSelect'
import Finder from 'components/Finder'

import { useHistory } from 'react-router-dom'

import axios from 'utils/axios'
import { normalizeKiosk } from 'utils/helpers'
// import { useRoby } from 'hooks/useContext'
import { UploadOutlined } from '@ant-design/icons'

import './styles.scss'
// import 'antd/dist/antd.css'
import styles from './styles.module.scss'

const FROM_TYPE = [
  {
    key: 1,
    label: `От клиента`,
    icon: AppleOutlined
  },
  {
    key: 2,
    label: `От мерчанта`,
    icon: AndroidOutlined
  }
]

export const NewTicketFormModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [problemPlace, setProblemPlace] = useState([])
  const [startDate] = useState(moment().subtract(5, 'minutes'))
  const [kiosks, setKiosks] = useState([])
  const [messageApi, contextHolder] = message.useMessage()
  const [pans, setPans] = useState([])
  const [clientKiosks, setClientKiosks] = useState([])
  const [loadingClientKiosk, setLoadingClientKiosk] = useState(false)
  const [merchants, setMerchants] = useState([])
  const [activeTab, setActiveTab] = useState(FROM_TYPE[0].key)

  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: ''
  })

  const handleCancel = () => setPreview({ ...preview, visible: false })

  const history = useHistory()

  const handleCloseModal = () => {
    getKiosks()
    resetForm()
    onClose()
  }

  const optionsKiosks = useMemo(() => {
    return kiosks.map((item) => ({
      id: item.id,
      label: normalizeKiosk(item)
    }))
  }, [kiosks])

  const getProblemPlace = async () => {
    const res = await axios.get('/public/ticket/problemplace')
    setProblemPlace(res.data)
  }

  const getKiosks = async (id) => {
    const res = await axios.get('/kiosk/', { params: { merchantId: id } })
    setKiosks(res.data)
  }

  useEffect(() => {
    getProblemPlace()
    getKiosks()
    getMerchants()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsPlaces = React.useMemo(() => {
    return problemPlace.map((item) => ({ id: item.id, label: item.name }))
  }, [problemPlace])

  const handleSave = async (v) => {
    const value = {
      ...v,
      kiosk:
        v.problemPlaceId === 1
          ? optionsKiosks.find((item) => item?.id === v?.truthKiosk)?.label
          : null
    }

    try {
      setLoading(true)
      const data = value
      const now = moment().format('YYYY-MM-DD HH:mm:ss.SSSZZ')
      data.formFilledDate = now
      data.detectedAt = moment(value.detectedAt).format(
        'YYYY-MM-DD HH:mm:ss.SSSZZ'
      )
      const formData = new FormData()
      for (let key in value) {
        if (key === 'photo') {
          if (value[key]) {
            for (let i = 0; i < value[key].length; i++) {
              formData.append(key, value[key][i].originFileObj)
            }
          } else {
            formData.append(key, value[key])
          }
        } else if (key === 'detectedAt') {
          formData.append(
            key,
            moment(value[key]).format('YYYY-MM-DD HH:mm:ss.SSSZZ')
          )
        } else {
          formData.append(key, value[key])
        }
      }
      const res = await axios.post('/ticket/create-ticket', formData)
      handleCloseModal()
      history.push(`/admin/ticket/${res.data.id}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (name) => (event) =>
    setFieldValue(name, event.target.value)

  const handleChangeDate = (name) => (value) =>
    setFieldValue(name, moment(value))

  const handleChangeSelect = (name) => (value) => {
    if (name === 'merchantId') {
      setFieldValue(name, value)
      setFieldValue('truthKiosk', '')
      getKiosks(value)
    }

    if (name === 'truthKiosk') {
      merchants.map((merchant) => {
        merchant.merchantKiosks.map((kiosk) => {
          if (kiosk.id === value) {
            setFieldValue('merchantId', merchant.id)
            return
          }
        })
      })
    }

    setFieldValue(name, value)
  }

  const isClient = FROM_TYPE[0].key === activeTab

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        clientName: '',
        clientPhone: '',
        truthKiosk: '',
        problemPlaceId: '',
        photo: undefined,
        description: '',
        detectedAt: startDate,
        merchantId: '',
        source: activeTab
      },
      validationSchema: yup.object().shape({
        clientName: isClient
          ? yup.string().required('Это поле обязательно')
          : yup.string(),
        clientPhone: isClient
          ? yup
              .string()
              .required('Это поле обязательно')
              .matches(
                /^(\+7|7|8)?[\s/-]?\(?[4893][0-9]{2}\)?[\s/-]?[0-9]{3}[\s/-]?[0-9]{2}[\s/-]?[0-9]{2}$/,
                'Неверный формат'
              )
          : yup.string(),
        problemPlaceId: yup.string().required('Это поле обязательно'),
        truthKiosk: yup.string().when('problemPlaceId', {
          is: '1',
          then: yup.string().required('Это поле обязательно')
        }),
        photo: yup.mixed().notRequired().yup,
        description: yup.string().required('Это поле обязательно'),
        detectedAt: yup.string().required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChangePhoto = (name) => (value) => {
    setFieldValue(name, value.fileList)
  }

  const handlePreview = (file) => {
    if (file.size > 600000) {
      return false
    }
    setPreview({
      visible: true,
      image: file.thumbUrl,
      title: file.name
    })
  }

  const handlerErrorMessage = () => {
    messageApi.open({
      type: 'error',
      content: 'Загружаемый файл больше допустимого размера 5 мегабайт',
      duration: 8
    })
  }

  const getClientKiosks = async (id) => {
    try {
      setLoadingClientKiosk(true)
      const res = await axios.get(`/client/${id}/kiosks`)
      setClientKiosks(res.data)
    } catch (e) {
      /* EMPTY */
    } finally {
      setLoadingClientKiosk(false)
    }
  }

  const findClient = (client) => {
    const { phone, name, pans, id } = client
    if (name) {
      setFieldValue('clientName', name)
      getClientKiosks(id)
    }
    setFieldValue('clientPhone', phone)
    setClientKiosks([])
    setPans(pans)
  }

  const getMerchants = useCallback(async () => {
    const res = await axios.get('/merchant')
    setMerchants(res.data)
  }, [])

  const optionsMerchants = useMemo(() => {
    const merchant = merchants.map((item) => ({
      id: item.id,
      label: item.name
    }))
    // merchant.unshift({ id: 0, label: 'Все' })
    return merchant
  }, [merchants])

  return (
    <Modal footer={false} open={open} onCancel={handleCloseModal}>
      <div className={styles.form_container}>
        <Tabs
          defaultActiveKey='1'
          items={FROM_TYPE}
          centered
          onChange={(activeKey) => {
            setFieldValue('source', activeKey)
            setActiveTab(activeKey)
          }}
        />
        {isClient && (
          <Form onFinish={handleSubmit} layout='vertical'>
            <Row>
              <Col span={12}>
                <Form.Item
                  required
                  label='Контактный номер телефона'
                  extra={
                    touched.clientPhone && (
                      <Error message={errors.clientPhone} />
                    )
                  }
                >
                  <Finder
                    defValue={values.clientPhone}
                    onFind={findClient}
                    onChangePhone={(data) => setFieldValue('clientPhone', data)}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={1}>
                <Form.Item
                  required
                  label='Имя'
                  extra={
                    touched.clientName && <Error message={errors.clientName} />
                  }
                >
                  <Input
                    value={values.clientName}
                    onChange={handleChange('clientName')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item label='Маскированные номера карт (PAN)'>
                {pans.length ? (
                  <Space size={[0, 8]} wrap>
                    {pans.map((pan) => {
                      return <Tag key={pan.id}>{pan.number}</Tag>
                    })}
                  </Space>
                ) : (
                  'Не определены'
                )}
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                label='Проблемные киоски клиента'
                className={styles.kiosksProblem}
              >
                {clientKiosks.length ? (
                  <div className={styles.tagsPanel}>
                    {clientKiosks.map((kiosk) => (
                      <Popover
                        content={`${normalizeKiosk(kiosk)}`}
                        key={kiosk.id}
                      >
                        <Tag className={styles.popover}>{`${normalizeKiosk(
                          kiosk
                        )}`}</Tag>
                      </Popover>
                    ))}
                  </div>
                ) : (
                  'Не определены'
                )}
              </Form.Item>
            </Row>

            <Form.Item
              required
              label='Место возникновения проблемы'
              extra={
                touched.problemPlaceId && (
                  <Error message={errors.problemPlaceId} />
                )
              }
            >
              <SingleSelect
                id='createTicketFormProblemPlace'
                options={optionsPlaces}
                value={values.problemPlaceId}
                onChange={handleChangeSelect('problemPlaceId')}
              />
            </Form.Item>
            <Form.Item
              // required={!conditionTypeTicket}
              label='Мерчант'
              // extra={
              //   touched.merchantId && <Error message={errors.merchantId} />
              // }
              // style={{ fontWeight: conditionMerchant ? 500 : 400 }}
            >
              <SingleSelect
                showSearch={true}
                value={values.merchantId}
                options={optionsMerchants}
                // disabled={conditionMerchant}
                onChange={handleChangeSelect('merchantId')}
              />
            </Form.Item>
            {values.problemPlaceId === 1 && (
              <Form.Item
                label='Адрес киоска'
                required
                extra={
                  touched.truthKiosk && <Error message={errors.truthKiosk} />
                }
              >
                <SingleSelect
                  showSearch={true}
                  value={values.truthKiosk}
                  options={optionsKiosks}
                  onChange={handleChangeSelect('truthKiosk')}
                />
              </Form.Item>
            )}
            <Form.Item
              required
              label={`Дата и время возникновения проблемы`}
              extra={
                touched.detectedAt && <Error message={errors.detectedAt} />
              }
            >
              <DatePicker
                selected={values.detectedAt._d}
                timeIntervals={30}
                onChange={handleChangeDate('detectedAt')}
              />
            </Form.Item>

            <Form.Item
              required
              label='Проблема'
              extra={
                touched.description && <Error message={errors.description} />
              }
            >
              <Input.TextArea
                value={values.description}
                onChange={handleChange('description')}
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>

            <Form.Item
              label='Файлы'
              extra={touched.photo && <Error message={errors.photo} />}
            >
              <Upload
                listType='picture'
                defaultFileList={values.photo}
                onChange={handleChangePhoto('photo')}
                onPreview={handlePreview}
                supportServerRender={null}
                multiple
                maxCount={5}
                beforeUpload={(file) => {
                  if (file.size > 600000) {
                    handlerErrorMessage('error')
                    return true
                  }
                  return false
                }}
              >
                <Button icon={<UploadOutlined />}>Загрузить файлы</Button>
              </Upload>

              <div className={styles.notif}>
                <Tag>Максимальное количество файлов 5</Tag>
                <Tag>Максимальный размер файла 5МБ</Tag>
              </div>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 9 }}>
              <Button loading={loading} htmlType='submit' type='primary'>
                Отправить
              </Button>
            </Form.Item>
          </Form>
        )}

        {FROM_TYPE[1].key === activeTab && (
          <Form onFinish={handleSubmit} layout='vertical'>
            <Form.Item
              // required={!conditionTypeTicket}
              label='Мерчант'
              // extra={
              //   touched.merchantId && <Error message={errors.merchantId} />
              // }
              // style={{ fontWeight: conditionMerchant ? 500 : 400 }}
            >
              <SingleSelect
                showSearch={true}
                value={values.merchantId}
                options={optionsMerchants}
                // disabled={conditionMerchant}
                onChange={handleChangeSelect('merchantId')}
              />
            </Form.Item>

            <Form.Item
              required
              label='Место возникновения проблемы'
              extra={
                touched.problemPlaceId && (
                  <Error message={errors.problemPlaceId} />
                )
              }
            >
              <SingleSelect
                id='createTicketFormProblemPlace'
                options={optionsPlaces}
                value={values.problemPlaceId}
                onChange={handleChangeSelect('problemPlaceId')}
              />
            </Form.Item>

            {values.problemPlaceId === 1 && (
              <Form.Item
                label='Адрес киоска'
                required
                extra={
                  touched.truthKiosk && <Error message={errors.truthKiosk} />
                }
              >
                <SingleSelect
                  showSearch={true}
                  value={values.truthKiosk}
                  options={optionsKiosks}
                  onChange={handleChangeSelect('truthKiosk')}
                />
              </Form.Item>
            )}

            <Form.Item
              required
              label={`Дата и время возникновения проблемы`}
              extra={
                touched.detectedAt && <Error message={errors.detectedAt} />
              }
            >
              <DatePicker
                selected={values.detectedAt._d}
                timeIntervals={30}
                onChange={handleChangeDate('detectedAt')}
              />
            </Form.Item>

            <Form.Item
              required
              label='Проблема'
              extra={
                touched.description && <Error message={errors.description} />
              }
            >
              <Input.TextArea
                value={values.description}
                onChange={handleChange('description')}
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>

            <Form.Item
              label='Файлы'
              extra={touched.photo && <Error message={errors.photo} />}
            >
              <Upload
                listType='picture'
                defaultFileList={values.photo}
                onChange={handleChangePhoto('photo')}
                onPreview={handlePreview}
                supportServerRender={null}
                multiple
                maxCount={5}
                beforeUpload={(file) => {
                  if (file.size > 600000) {
                    handlerErrorMessage('error')
                    return true
                  }
                  return false
                }}
              >
                <Button icon={<UploadOutlined />}>Загрузить файлы</Button>
              </Upload>

              <div className={styles.notif}>
                <Tag>Максимальное количество файлов 5</Tag>
                <Tag>Максимальный размер файла 5МБ</Tag>
              </div>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 9 }}>
              <Button loading={loading} htmlType='submit' type='primary'>
                Создать
              </Button>
            </Form.Item>
          </Form>
        )}

        <Modal
          open={preview.visible}
          title={preview.title}
          onCancel={handleCancel}
          footer={null}
        >
          <img
            alt={preview.title}
            style={{ width: '100%' }}
            src={preview.image}
          />
        </Modal>
        {contextHolder}
      </div>
    </Modal>
  )
}

export const DoubleRow = (props) => {
  const { firstPart, secondPart, secondSmall = false } = props
  return (
    <div>
      <p className={clsx(styles.rowP, styles.firstRow)}>{firstPart}</p>
      <p className={clsx(styles.rowP, secondSmall && styles.smallP)}>
        {secondPart}
      </p>
    </div>
  )
}
