import { Route, Switch, Redirect } from 'react-router-dom'
import React from 'react'

import AdminRoute from 'components/AdminRoute'

import Form from 'pages/Form'
import Article from 'pages/Article'
import Roles from 'pages/Roles'
import Users from 'pages/Users'
import Reports from 'pages/Reports'
import Tickets from 'pages/Tickets'
import Problems from 'pages/Problems'
import { Profile } from 'pages/Profile'
import Softwares from 'pages/Softwares'
import Tags from 'pages/Tags'
import Merchants from 'pages/Merchants'
import { TicketInfo } from 'pages/TicketInfo'
import ProblemPLace from 'pages/ProblemPlace'
import TicketCard from 'pages/Tickets/TicketCard'
import { MerchantCard } from 'pages/Merchants/MerchantCard'
import { MerchantBotMessages } from 'pages/MerchantBotMessages'
import ProblemCard from 'pages/Problems/ProblemCard'
import KnowledgeBase from 'pages/KnowledgeBase'
import ResetPassword from 'pages/ResetPassword'
import TicketsTypes from 'pages/TicketsTypes'
import Incidents from 'pages/Incidents'
import IncidentCard from 'pages/Incidents/IncidentCard'
import Schedule from 'pages/Schedule'
import Announcement from 'pages/Announcement'

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/admin/tickets' />
      </Route>

      <Route exact path='/reset-password/:token' component={ResetPassword} />
      <Route exact path='/ticket' component={Form} />
      <Route exact path='/article/:id' component={Article} />
      <Route exact path='/ticket/:id' component={TicketInfo} />

      <Route exact path='/admin/tickets' component={Tickets} />
      <Route exact path='/admin/incidents' component={Incidents} />
      <Route exact path='/admin/incident/:id' component={IncidentCard} />
      <Route exact path='/admin/types' component={TicketsTypes} />

      <Route path='/admin/schedule' component={Schedule} />
      <Route path='/admin/announcement' component={Announcement} />
      <Route exact path='/admin/ticket/:id' component={TicketCard} />
      <Route exact path='/admin/problems' component={Problems} />
      <Route exact path='/admin/problem/:id' component={ProblemCard} />
      <Route exact path='/admin/profile' component={Profile} />

      <Route exact path='/knowledge-base' component={KnowledgeBase} />

      <Route exact path='/admin/merchants' component={Merchants} />
      <Route exact path='/admin/merchant/:id' component={MerchantCard} />

      {/* <AdminRoute path='/admin/requests' component={Requests} /> */}
      <AdminRoute path='/admin/softwares' component={Softwares} />

      <AdminRoute path='/admin/tags' component={Tags} />
      {/* <AdminRoute path='/admin/merchants' component={Merchants} /> */}
      {/* <AdminRoute path='/admin/merchants' component={Merchants} /> */}
      <AdminRoute path='/admin/users' component={Users} />
      <AdminRoute path='/admin/roles' component={Roles} />
      <AdminRoute path='/admin/problemplace' component={ProblemPLace} />
      <AdminRoute
        path='/admin/merchant-bot-message'
        component={MerchantBotMessages}
      />
      <AdminRoute allow path='/admin/reports' component={Reports} />
    </Switch>
  )
}

export default Routes
