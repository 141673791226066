import * as yup from 'yup'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Form, Input, Button, Row, message } from 'antd'
import Error from 'components/Error'
import { version } from '../../../package.json'
import axios from 'utils/axios'

import { useProfile } from 'hooks/useContext'

// import 'antd/dist/antd.css'
import styles from './styles.module.scss'

const SCREENS = {
  LOGIN: 'login',
  FORGOT: 'forgot',
  RESET: 'reset'
}

const Forgot = ({ onScreenChange, email }) => {
  const [loading, setLoading] = useState(false)

  const handleSave = async (value) => {
    try {
      setLoading(true)
      const res = await axios.post('/auth/forgot', value)
      message.success(res?.message)
      onScreenChange(SCREENS.RESET, value.email)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: (values) => handleSave(values),
    initialValues: {
      email
    },

    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required('Это поле обязательно')
        .email('Некорректный email')
    })
  })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <Form
      layout='vertical'
      className={styles.form}
      onFinish={handleSubmit}
      initialValues={{ email }}
    >
      <Form.Item
        label='Почта'
        required
        name='email'
        extra={touched.email && <Error message={errors.email} />}
      >
        <Input
          placeholder='email'
          value={values.email}
          onChange={handleChange('email')}
        />
      </Form.Item>

      <Row>
        <Form.Item className={styles.lastBlock}>
          <Button loading={loading} type='primary' htmlType='submit'>
            Сбросить
          </Button>
        </Form.Item>

        <Form.Item className={styles.lastBlock} style={{ marginLeft: 10 }}>
          <Button onClick={() => onScreenChange(SCREENS.LOGIN)}>Отмена</Button>
        </Form.Item>
      </Row>
    </Form>
  )
}

const Login = ({ onScreenChange }) => {
  const [loading, setLoading] = useState(false)

  const { login } = useProfile()

  const handleSave = async (value) => {
    try {
      setLoading(true)
      const res = await axios.post('/auth', value)
      login(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: (values) => handleSave(values),
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required('Это поле обязательно')
        .email('Некорректный email'),
      password: yup.string().required('Это поле обязательно')
    }),
    enableReinitialize: true
  })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }

  return (
    <Form layout='vertical' className={styles.form} onFinish={handleSubmit}>
      <Form.Item
        label='Логин'
        required
        name='email'
        extra={touched.email && <Error message={errors.email} />}
      >
        <Input
          placeholder='email'
          value={values.email}
          onChange={handleChange('email')}
        />
      </Form.Item>

      <Form.Item
        required
        label='Пароль'
        name='password'
        extra={touched.password && <Error message={errors.password} />}
      >
        <Input.Password
          placeholder='Пароль'
          value={values.password}
          onChange={handleChange('password')}
        />
      </Form.Item>

      <Form.Item className={styles.lastBlock}>
        <Button
          loading={loading}
          type='primary'
          htmlType='submit'
          style={{ width: '100%' }}
        >
          Войти
        </Button>
      </Form.Item>

      <Form.Item className={styles.lastBlock}>
        <Button
          loading={loading}
          type='link'
          onClick={() => onScreenChange(SCREENS.FORGOT, values.email)}
        >
          Забыли пароль?
        </Button>
      </Form.Item>
    </Form>
  )
}

const Reset = ({ onScreenChange, email }) => {
  return (
    <div>
      <p className={styles.message}>
        Ссылка на сброс пароля отправлена вам на почту
      </p>
      <div className={styles.email}>
        <p>{email}</p>
      </div>
      <Row className={styles.block}>
        <Button
          type='primary'
          onClick={() => onScreenChange(SCREENS.LOGIN)}
          style={{ width: 200 }}
        >
          ОК
        </Button>
      </Row>
    </div>
  )
}

const Auth = () => {
  const [screen, setScreen] = useState(SCREENS.LOGIN)
  const [email, setEmail] = useState('')

  const handleChangeScreen = (screen, email) => {
    setScreen(screen)
    setEmail(email)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.loginContainer}>
        <h1 className={styles.logo} data-text={'ServiceDesk'}>
          ServiceDesk
        </h1>
        {SCREENS.LOGIN === screen && (
          <Login
            onScreenChange={(screen, email) =>
              handleChangeScreen(screen, email)
            }
          />
        )}
        {SCREENS.FORGOT === screen && (
          <Forgot
            onScreenChange={(screen, email) =>
              handleChangeScreen(screen, email)
            }
            email={email}
          />
        )}
        {SCREENS.RESET === screen && (
          <Reset
            onScreenChange={(screen) => handleChangeScreen(screen)}
            email={email}
          />
        )}

        <div className={styles.verison}>
          <span>Версия {version}</span>
        </div>
      </div>
    </div>
  )
}

export default Auth
