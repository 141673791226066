import { Table } from 'antd'
import React, { useMemo } from 'react'

import styles from './styles.module.scss'

const BugTable = ({ data, loading }) => {
  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '100px'
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        width: '100%'
      },
      {
        title: 'Состояние',
        dataIndex: 'state',
        key: 'state',
        width: '120px'
      }
    ],
    []
  )

  const handleClickRow = (record) => {
    return {
      onClick: () =>
        window.open(
          `https://solution-factory.visualstudio.com/Robymarket/_workitems/edit/${record.id}`,
          '_blank'
        )
    }
  }

  const sortedDataSource = data.slice().sort((a, b) => b.id - a.id)

  return (
    <Table
      dataSource={sortedDataSource}
      columns={columns}
      onRow={handleClickRow}
      pagination={false}
      rowClassName={styles.row}
      scroll={{ y: 500, x: 500 }}
      loading={loading}
    />
  )
}

export default BugTable
