import { Modal } from 'antd'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Button, Form } from 'antd'
import SingleSelect from 'components/SingleSelect'
import axios from 'utils/axios'

export const ChangeStatusTicketModal = ({
  isOpen,
  onClose,
  ticketId,
  currentTicketId,
  saveAndClose
}) => {
  const [editing, setEditing] = useState({ is: false, id: null })

  const [loading, setLoading] = useState(false)
  const [statuses, setStatuses] = useState([])

  const statusesOption = useMemo(() => {
    return statuses
      .map((status) => ({
        id: status.id,
        label: status.name
      }))
      .filter((u) => u.id !== currentTicketId && u.id !== 1 && u.id !== 2)
  }, [statuses, currentTicketId])

  const loadStatuses = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/ticket/statuses`)
      setEditing({ is: false, id: null })

      setStatuses(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadStatuses()
  }, [])

  const changeSelectHandler = (e) => {
    setEditing({ is: false, id: e })
  }

  const handleSubmit = useCallback(async () => {
    if (editing.id) {
      try {
        setLoading(true)
        setEditing({ is: false, id: null })
        await axios.put(`/ticket/${ticketId}/change-status`, {
          status: editing.id
        })
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
        const status = statuses.find((status) => status.id === editing.id).name
        saveAndClose(status)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTicketId, ticketId, editing.id])

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={false}
      title={`Изменить статуc инцидента`}
      destroyOnClose={true}
    >
      <Form layout='vertical' onFinish={handleSubmit}>
        <Form.Item label='Выберете статус'>
          <SingleSelect
            options={statusesOption}
            onChange={changeSelectHandler}
          />
        </Form.Item>
        <Form.Item>
          {editing.id && (
            <Button loading={loading} type='primary' htmlType='submit'>
              Сохранить
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}
