import moment from 'moment'
import isImage from 'is-image'
import dayjs from 'dayjs'
export const getTicketStatus = (id) => {
  let status
  switch (id) {
    case 1:
      status = 'Новый'
      break
    case 2:
      status = 'Анализ'
      break
    case 3:
      status = 'Решение'
      break
    case 4:
      status = 'Закрыто'
      break
    default:
      break
  }
  return status
}

export const makeColorIndicator = (elem) => {
  let colorIndicator
  switch (elem) {
    case 'Новый':
      colorIndicator = '#FFFF66'
      break
    case 'Анализ':
      colorIndicator = '#FF6600'
      break
    case 'Решение':
      colorIndicator = '#CC33FF'
      break
    case 'Закрыто':
      colorIndicator = '#99FF66'
      break
    default:
      break
  }

  return colorIndicator
}

export const makeProblemColorIndicator = (elem) => {
  let colorIndicator
  switch (elem) {
    case 'Новая':
      colorIndicator = '#FFFF66'
      break
    case 'Запланирована в бэклог':
      colorIndicator = '#3300FF'
      break
    case 'Запланирована в текущий спринт':
      colorIndicator = '#66FFFF'
      break
    case 'Решена':
      colorIndicator = '#CCFF33'
      break
    case 'Закрыта':
      colorIndicator = '#33FF00'
      break
    default:
      break
  }

  return colorIndicator
}

export const parseStringToDate = (date) => {
  return moment(date, 'DD.MM.YYYY HH:mm:ss').format('YYYY.MM.DD HH:mm:ss')
}

export const parseDbTimestamp = (date) => {
  return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY.MM.DD HH:mm:ss')
}

export const toDbTimestamp = (date) => {
  return moment(date).toDate()
}

export const formatTime = (value) => {
  return moment(value, 'yyyy-MM-DD HH:mm:ss.SSZ').format('DD.MM.YYYY HH:mm:ss')
}

export const formatToTimestamp = (time) => {
  return dayjs(time).format('YYYY-MM-DD HH:mm:ss.SSSZZ')
}

export const API_DATE_FORMAT = 'YYYY-MM-DD'

export const dateToApiDateFormat = (date) => {
  if (!dayjs.isDayjs(date)) return date
  return date.format(API_DATE_FORMAT)
}

export const getDateFromApiDateFormat = (date) => {
  if (!date) return date

  return dayjs(date)
}

export const getTimeByStatus = (array, status) => {
  let time
  let temp
  array.forEach((item) => {
    if (item.status.name.toLowerCase() === status.toLowerCase()) {
      time = item.createdAt
      temp = item.createdAt
    } else {
      time = '-'
    }
  })
  return temp ? formatTime(temp) : time
}

export const getUserByStatus = (array, status) => {
  let user
  let temp
  array.forEach((item) => {
    if (item.status.name.toLowerCase() === status.toLowerCase()) {
      user = item.user?.name
      temp = item.user?.name
    } else {
      user = '-'
    }
  })

  return temp ? temp : user
}

export const getFilesByType = (files, findImage = true) => {
  const yourFiles = []

  for (let i = 0; i < files.length; i++) {
    if (findImage) {
      if (isImage(files[i].path)) {
        yourFiles.push(files[i].path)
      }
    } else {
      if (!isImage(files[i].path)) yourFiles.push(files[i].path)
    }
  }

  return yourFiles
}

export const PHONE_NUMBERS = [
  '+7-(999)-448-38-07',
  '+7-(963)-183-27-24',
  '+7-123-123-12-31'
]

export const findWord = async (word, string) => {
  return await string.split(' ').find((item) => item === word)
}

export const convertMinsToHrsMins = (minutes) => {
  let h = Math.floor(minutes / 60)
  let m = minutes % 60
  h = h < 10 ? '0' + h : h
  m = m < 10 ? '0' + m : m
  return h + ':' + m
}

export const parseTimeToMins = (time) => {
  if (time === '-') {
    return null
  }

  const hrs = Number(time.split(':')[0])
  const mins = Number(time.split(':')[1])

  const minsInHrs = Number(hrs) * 60
  if (Number(minsInHrs + Number(mins)) === 0) {
    return 0.000001
  }
  return Number(minsInHrs + Number(mins))
}

export const normalizeKiosk = (kiosk) => {
  return `г. ${kiosk?.city}, ул. ${kiosk?.street}, д. ${kiosk?.house}. Киоск № ${kiosk?.number}`
}

export const normalizeKioskAddress = (kiosk) => {
  return `г. ${kiosk.city}, ул. ${kiosk.street}, д. ${kiosk.house}. Киоск № ${kiosk.number}`
}

export const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch {
    return false
  }
  return true
}

export const tryParseJson = (str) => {
  try {
    const obj = JSON.parse(str)
    return obj
  } catch {
    return str
  }
}

export const minDate = (all_dates) => {
  let min_dt = all_dates[0],
    min_dtObj = new Date(all_dates[0])
  all_dates.forEach(function (dt) {
    if (new Date(dt) < min_dtObj) {
      min_dt = dt
      min_dtObj = new Date(dt)
    }
  })
  return min_dt
}

export const getDeltaInHrsMins = (start, end) => {
  let dateStart = moment(start)
  let dateEnd = moment(end)
  let temp = dateStart

  if (dateStart.isBefore(dateEnd)) {
    dateStart = dateEnd
    dateEnd = temp
  }

  var duration = moment.duration(dateStart.diff(dateEnd))

  let delta = duration.asSeconds()

  // В оставшихся секунд вычленяем количество полных часов
  let hours = Math.floor(delta / 3600)

  // Также их потом вычитаем, выразив в секундах
  delta -= hours * 3600
  // Из оставшихся секунд берем минуты
  let minutes = Math.floor(delta / 60) % 60
  // Опять вычитаем
  delta -= minutes * 60
  // И наконец секунды
  // В теории  деление по модулю на 60 не обязателен
  let seconds = Math.floor(delta % 60)
  // Итоговая дата

  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }:${seconds < 10 ? '0' + seconds : seconds}`
}

export const getAllDatesPerStatus = (data, status) => {
  const tempDates = []
  for (const element of data) {
    if (element.status.name === status) {
      tempDates.push(element.createdAt)
    }
  }
  if (tempDates.length === 0) {
    return null
  }

  return tempDates
}

export const getUserName = (data, status) => {
  const tempDates = []
  for (const element of data) {
    if (element.status.name === status) {
      tempDates.push(element.createdAt)
    }
  }
  if (tempDates.length === 0) {
    return null
  }

  let min_dt = tempDates[0],
    min_dtObj = new Date(tempDates[0])
  tempDates.forEach(function (dt) {
    if (new Date(dt) < min_dtObj) {
      min_dt = dt
      min_dtObj = new Date(dt)
    }
  })

  return data.find((item) => item.createdAt === min_dt).user.name
}

export const paramsStringify = (obj) => {
  return `?${Object.entries(obj)
    .flatMap(([k, v]) => {
      if (Array.isArray(v)) {
        return v.map((i) => k + '=' + i)
      } else if (dayjs.isDayjs(v)) {
        return [k + '=' + dateToApiDateFormat(v)]
      } else {
        return [k + '=' + v]
      }
    })
    .join('&')}`
}

export const parseParams = (querystring, keyCbs) => {
  const query = querystring.slice(1)
  const params = new URLSearchParams(query)

  const obj = {}

  for (const key of params.keys()) {
    const values = params.getAll(key)

    if (values.length > 1) {
      if (key === 'page' || key === 'perPage') {
        obj[key] = Number(values)
      } else if (key === 'newMessage') {
        obj[key] = values === 'true' ? true : false
      } else if (
        key === 'status' ||
        key === 'type' ||
        key === 'tags' ||
        key === 'users'
      ) {
        obj[key] = values.map((i) => +i)
      } else {
        obj[key] = params.getAll(key)
      }
    } else {
      if (key === 'page' || key === 'perPage') {
        obj[key] = Number(params.get(key))
      } else if (
        key === 'status' ||
        key === 'type' ||
        key === 'tags' ||
        key === 'users'
      ) {
        if (Number(params.get(key)) > 0) {
          obj[key] = [Number(params.get(key))]
        }
      } else if (key === 'clientPhone') {
        let value = params.get(key)
        if (value) {
          value = '+' + value.slice(1)
        }
        obj[key] = value
      } else {
        const value = params.get(key)

        obj[key] = (() => {
          switch (value) {
            case 'null':
              return null
            case 'undefined':
              return undefined
            case 'true':
              return true
            case 'false':
              return false
            default:
              return value ? value : ''
          }
        })()

        if (keyCbs) {
          const keyCb = keyCbs.find((k) => k.key === key)
          if (keyCb) {
            obj[key] = keyCb.cb(obj[key])
            continue
          }
        }
      }
    }
  }
  return obj
}

export const compareObjects = (newObj, oldObj) => {
  if (Object.keys(oldObj).length === 0 && Object.keys(newObj).length > 0)
    return newObj

  let diff = {}
  for (const key in oldObj) {
    if (newObj[key] && oldObj[key] !== newObj[key]) {
      diff[key] = newObj[key]
    }
  }

  if (Object.keys(diff).length > 0) return diff

  return null
}

export const filterUniqueByField = (objects, field) => {
  const valueSet = new Set()
  const uniqueObjects = []

  for (const obj of objects) {
    const value = obj[field]
    if (!valueSet.has(value)) {
      valueSet.add(value)
      uniqueObjects.push(obj)
    }
  }

  return uniqueObjects
}
