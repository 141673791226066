import clsx from 'clsx'
import { Button, Modal, Input } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import styles from './styles.module.scss'
import { useClickOutside } from 'hooks/useClickOutside'

const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: []
}

export const TableSelect = ({ onChange, className, options, onSave }) => {
  const wrapperRef = useRef(null)
  const [_options, setOptions] = useState([])
  const [visible, setVisible] = useState(false)
  const [nameModalOpen, setNameModalOpen] = useState(false)
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState)

  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position)
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: _options
    })
    event.dataTransfer.setData('text/html', '')
  }

  const saveClickHandler = (name) => {
    setNameModalOpen(false)
    onSave({
      columns: Object.values(
        _options
          .filter((option) => option.selected !== false)
          .map((item) => item.id)
      ),
      name
    })
  }

  const onDragOver = (event) => {
    event.preventDefault()

    let newList = dragAndDrop.originalOrder
    const draggedFrom = dragAndDrop.draggedFrom
    const draggedTo = Number(event.currentTarget.dataset.position)

    const itemDragged = newList[draggedFrom]
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    )

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ]

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo
      })
    }
  }

  const onDrop = () => {
    setOptions(dragAndDrop.updatedOrder)

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    })
  }

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null
    })
  }

  useEffect(() => {
    const temp = []
    for (const option of options) {
      temp.push({ ...option, selected: true })
    }
    setOptions(temp)
  }, [options])

  useClickOutside(wrapperRef, () => setVisible(false))

  const rowClickHandler = (index) => () => {
    setOptions(
      _options.flatMap((option, i) =>
        index === i ? { ...option, selected: !option.selected } : { ...option }
      )
    )
  }

  useEffect(() => {
    onChange(
      Object.values(
        _options
          .filter((option) => option.selected !== false)
          .map((item) => item.id)
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_options])

  return (
    <div className={clsx(styles.s_mainContainer, className)} ref={wrapperRef}>
      <span className={styles.select}>
        <input
          type='search'
          unselectable
          readOnly
          onClick={() => setVisible(!visible)}
          className={clsx(styles.s_input, visible && styles.s_input__visible)}
          value='Столбцы'
        />
        <div className={clsx(styles.s_options, visible && styles.visible)}>
          {options.length > 0 ? (
            <div>
              {_options.map((option, index) => (
                <div
                  draggable
                  key={index}
                  data-position={index}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  onDragLeave={onDragLeave}
                  className={clsx(
                    dragAndDrop &&
                      dragAndDrop.draggedTo === Number(index) &&
                      styles.dropArea
                  )}
                >
                  <div
                    key={option.id + index}
                    className={clsx(
                      styles.option_item,
                      option.selected && styles.item__active
                    )}
                    onClick={rowClickHandler(index)}
                  >
                    <span className={styles.option_label}>{option.label}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <span>Нет данных</span>
          )}
        </div>
        {onSave && (
          <Button
            className={clsx(styles.saveButton, visible && styles.visible)}
            type='primary'
            onClick={() => setNameModalOpen(true)}
          >
            Сохранить
          </Button>
        )}
      </span>
      <NameModal
        onClose={() => setNameModalOpen(false)}
        open={nameModalOpen}
        onSave={saveClickHandler}
      />
    </div>
  )
}

const NameModal = ({ open, onClose, onSave }) => {
  const [name, setName] = useState('')

  const closeModalHandler = () => {
    setName('')
    onClose()
  }

  const saveHandler = () => {
    setName('')
    onSave(name)
  }

  return (
    <Modal footer={false} open={open} onCancel={onClose}>
      <div className={styles.modal}>
        <Input
          value={name}
          placeholder='Придумайте название'
          onChange={(ev) => setName(ev.target.value)}
          onPressEnter={() => onSave(name)}
        />
        <div className={styles.modal_button}>
          <Button type='primary' onClick={saveHandler}>
            Сохранить
          </Button>
          <Button type='default' onClick={closeModalHandler}>
            Отменить
          </Button>
        </div>
      </div>
    </Modal>
  )
}
