import { useCallback, useState } from 'react'
import axios from 'utils/axios'

export const useAzuro = () => {
  const [currentSprint, setCurrentSprint] = useState(null)
  const [tasks, setTasks] = useState([])
  const createBug = useCallback(async (data) => {
    try {
      const res = await axios.post(`/azuro/bug`, data)
      return res
    } catch (e) {
      console.log(e)
    }
  }, [])

  const getSprint = useCallback(async () => {
    try {
      const res = await axios.get(`/azuro/current-sprint`)
      setCurrentSprint(res.data)
      return res
    } catch (e) {
      console.log(e)
    }
  }, [])

  const getTasks = useCallback(async (id) => {
    try {
      const res = await axios.get(`/azuro/${id}/tasks`)
      setTasks(res.data)
      return res
    } catch (e) {
      console.log(e)
    }
  }, [])

  return { createBug, getSprint, getTasks, currentSprint, tasks }
}
